import axios from "axios"
import Config from "../utils/Config"
import api from "./api"

class CandidateService {
  async listAll() {
    return api({
      url: process.env.REACT_APP_API_URL + "candidate/find-all",
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async listByElection(id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `candidate/find/${id}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async importCandidates(body: any, electionId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `candidate/import/${electionId}`,
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async importOptions(body: any, electionId: number) {
    return api({
      url:
        process.env.REACT_APP_API_URL +
        `candidate/options/import/${electionId}`,
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async update(data: any, id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `candidate/${id}`,
      method: "PUT",
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async updateWithFile(body: any, id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `candidate/file/${id}`,
      method: "PUT",
      timeout: Config.TIMEOUT_REQUEST,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getImages(electionId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `candidate/images/${electionId}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async deleteOne(candidateId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `candidate/${candidateId}`,
      method: "DELETE",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async deleteAll(electionId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `candidate/delete/all/${electionId}`,
      method: "DELETE",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const candidateService = new CandidateService()
export default candidateService
