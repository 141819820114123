import { Button, NavLogo } from "../index"
import { Link, useNavigate } from "react-router-dom"
import loginService from "../../services/LoginServices"
import { useContext, useState } from "react"
import AuthContext from "../../context/auth"
import { UserType } from "../../enums/UserType"

export function Nav() {
  const [login, setLogin] = useState(localStorage.getItem("hash"))
  const { markAsNotSigned, user } = useContext(AuthContext)
  const [type, setType] = useState(window.sessionStorage.getItem("type"))

  const managerNavItems = [
    {
      display: "Eleições",
      to: "/elections",
    },
  ]

  const adminNavItems = [
    {
      display: "Empresas",
      to: "/list-company",
    },
    {
      display: "Usuários",
      to: "/list-users",
    },
    {
      display: "Eleições",
      to: "/elections",
    },
  ]

  const navigate = useNavigate()

  const handleLogout = () => {
    loginService
      .logout()
      .then(() => {
        localStorage.clear()
        window.sessionStorage.clear()
        markAsNotSigned()
        setLogin(null)
        navigate("/")
      })
      .catch((error) => {
        console.error("Logout error:", error)
      })
  }

  return (
    <nav className="w-full h-20 bg-gray-100 border-b border-gray-300">
      <div className="w-full h-full max-w-7xl m-auto flex items-center justify-between">
        <NavLogo />

        <div className="flex items-center gap-4">
          {type === UserType.MANAGER &&
            managerNavItems.map((item, index) => (
              <Link to={item.to} key={index}>
                <Button variant="primary">{item.display}</Button>
              </Link>
            ))}

          {type === UserType.ADMIN &&
            adminNavItems.map((item, index) => (
              <Link to={item.to} key={index}>
                <Button variant="primary">{item.display}</Button>
              </Link>
            ))}

          <div className="ml-auto mr-4" />

          <div className="flex items-center">
            {user && (
              <span className="mr-4 text-gray-700 font-medium">
                <b>{user.name}</b>
              </span>
            )}

            <Link to="/">
              <button onClick={handleLogout} className="btn btn-warning">
                Sair
              </button>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}
