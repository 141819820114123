import React from "react";
import { Editor } from "@tinymce/tinymce-react";

interface TinyMCEEditorProps {
  value: string | undefined;
  onBlur: (content: string | undefined) => void;
  onChange: (content: string) => void;
}

const TinyMCEEditor: React.FC<TinyMCEEditorProps> = ({
  value,
  onBlur,
  onChange,
}) => {
  return (
    <Editor
      apiKey="ejdyca3cmp0o4mby3ehqlnc3mg418d3giuii694q163z04pd"
      initialValue={value}
      init={{
        directionality: "ltr",
        height: 200,
        menubar: false,
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help",
      }}
      onEditorChange={onChange}
      onBlur={() => onBlur(value)}
    />
  );
};

export default TinyMCEEditor;
