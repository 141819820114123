import Config from "../utils/Config"
import api from "./api"
import { CertificateType } from "../enums/certificate.type.enum"

class ElectionService {
  async adminGetAll() {
    return api({
      url: "election/",
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async listAll() {
    return api({
      url: "election/company",
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getElection(electionId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `election/${electionId}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getElectionInfo(electionId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `election/info/${electionId}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async create(data: any) {
    return api({
      url: process.env.REACT_APP_API_URL + "election",
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async createWithFile(body: any) {
    return api({
      url: process.env.REACT_APP_API_URL + "election/file",
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
      transformRequest: (data, headers) => {
        return body
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getFile(fileName: string) {
    return api({
      url: process.env.REACT_APP_API_URL + `election/download/${fileName}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async update(data: any, id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `election/${id}`,
      method: "PUT",
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async updateWithFile(body: any, id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `election/file/${id}`,
      method: "PUT",
      timeout: Config.TIMEOUT_REQUEST,
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
      transformRequest: (data, headers) => {
        return body
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async delete(id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `election/delete/${id}`,
      method: "DELETE",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getDigitalCertificate(
    electionId: number,
    certificateType: CertificateType
  ) {
    console.log("electionId", electionId)
    return api({
      url:
        process.env.REACT_APP_API_URL +
        `election/get-digital-certificate/${electionId}/${certificateType}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const electionService = new ElectionService()
export default electionService
