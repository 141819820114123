import React from "react";
import { Modal, Button } from "react-bootstrap";

type Props = {
  show: boolean;
  onHide: () => void;
  buttonHref: string;
  modalTitle?: string;
  imgSrc: string;
  errorMsg?: string;
};

const ModalErrorImport: React.FC<Props> = ({
  show,
  onHide,
  buttonHref,
  modalTitle,
  imgSrc,
  errorMsg,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className="bg-secondary" style={{ color: "white" }}>
        <Modal.Title>Erro ao importar {modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="instructionText">
          <p>
            Verifique a quantidade de colunas na planilha e se o nome de cada
            uma delas está correto, conforme mostrado na figura abaixo:
          </p>
          <img src={imgSrc} alt="" />
          <br />
          <p>Se preferir, faça o dowload do arquivo modelo.</p>

          {errorMsg && (
            <div>
              <p style={{ color: "orange" }}>Aviso: </p>
              <p>{errorMsg}</p>
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="warning" href={buttonHref}>
          Download do arquivo modelo
        </Button>

        <Button variant="primary" onClick={onHide}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalErrorImport;
