import "./App.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Login from "./pages/Login"
import UserList from "./pages/UserList"
import { AuthProvider } from "./context/auth"
import PrivateRoute from "./components/privateRoute/PrivateRoute"
import CompanyList from "./pages/CompanyList"
import ElectionList from "./pages/ElectionList"
import ElectorsList from "./pages/ElectorsList"
import CandidatesList from "./pages/CandidatesList"
import Vote from "./pages/Vote"
import VoteWithoutLink from "./pages/VotingWithoutLink"
import { UserType } from "./enums/UserType"
import ZeroReport from "./pages/ZeroReport"
import ElectionFinalReportPage from "./pages/FinalReport"
import InitialCertificate from "./pages/InitialCertificate"
import FinalCertificate from "./pages/FinalCertificate"
import VotersAndNoVotersReport from "./pages/VotersAndNoVotersReport"
import ElectorsReport from "./pages/ElectorsReport"

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route>
            {" "}
            <Route
              path="/list-company"
              element={
                <PrivateRoute
                  role={[UserType.ADMIN]}
                  element={<CompanyList />}
                />
              }
            />
            <Route
              path="/list-users"
              element={
                <PrivateRoute role={[UserType.ADMIN]} element={<UserList />} />
              }
            />
            <Route
              path="/elections"
              element={
                <PrivateRoute
                  role={[UserType.MANAGER, UserType.ADMIN]}
                  element={<ElectionList />}
                />
              }
            />
            <Route
              path="/electors/:electionId"
              element={
                <PrivateRoute
                  role={[UserType.ADMIN, UserType.MANAGER]}
                  element={<ElectorsList />}
                />
              }
            />
            <Route
              path="/candidates/:electionId"
              element={
                <PrivateRoute
                  role={[UserType.ADMIN, UserType.MANAGER]}
                  element={<CandidatesList />}
                />
              }
            />
            <Route
              path="/voters-and-non-voters-report/:electionId"
              element={
                <PrivateRoute
                  role={[UserType.ADMIN, UserType.MANAGER]}
                  element={<VotersAndNoVotersReport />}
                />
              }
            />
            <Route
              path="/zero-report/:electionId"
              element={
                <PrivateRoute
                  role={[UserType.ADMIN, UserType.MANAGER]}
                  element={<ZeroReport />}
                />
              }
            />
            <Route
              path="/electors-report/:electionId"
              element={
                <PrivateRoute
                  role={[UserType.ADMIN, UserType.MANAGER]}
                  element={<ElectorsReport />}
                />
              }
            />
            <Route
              path="/final-report/:electionId"
              element={
                <PrivateRoute
                  role={[UserType.ADMIN, UserType.MANAGER]}
                  element={<ElectionFinalReportPage />}
                />
              }
            />
            <Route
              path="/initial-certificate/:electionId"
              element={
                <PrivateRoute
                  role={[UserType.ADMIN, UserType.MANAGER]}
                  element={<InitialCertificate />}
                />
              }
            />
            <Route
              path="/final-certificate/:electionId"
              element={
                <PrivateRoute
                  role={[UserType.ADMIN, UserType.MANAGER]}
                  element={<FinalCertificate />}
                />
              }
            />
            <Route path="/election/:hash" element={<Vote />} />
            <Route path="/vote-without-link" element={<VoteWithoutLink />} />
            <Route path="/" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
