import React, { useContext, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.scss"
import { Button, Form } from "react-bootstrap"
import loginService from "../../services/LoginServices"
import { useLocation, useNavigate } from "react-router-dom"
import AuthContext from "../../context/auth"

const Login = () => {
  const location = useLocation()
  const [usuario, setUsuario] = useState("")
  const [password, setPassword] = useState("")
  const [path, setPath] = useState(location.state?.path)
  const navigate = useNavigate()
  const { selectUser, markAsSigned } = useContext(AuthContext)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    let data = {
      username: usuario,
      password: password,
    }

    loginService
      .login(data)
      .then((response) => {
        localStorage.setItem("hash", response.data.access_token)
        selectUser({ name: response.data.name, type: response.data.type })
        markAsSigned()
        const type = response.data.type
        // const userId = response.data.user_id;
        window.sessionStorage.setItem("type", type)

        if (path) {
          navigate(path)
        } else {
          if (type === "ADMIN") {
            navigate("/list-users")
          } else {
            navigate("/elections")
          }
        }
      })
      .catch((error) => {
        alert("Usuário não encontrado")
      })
  }

  return (
    <>
      <div className="container-fluid">
        <div className="logo">
          <img src="logo.png" alt="logo" />
        </div>
        <div className="form-div">
          <div className="form-header">
            <h1 className="header-login">Login</h1>
          </div>
          <div className="form-body">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-5">
                <Form.Control
                  id="username"
                  type="text"
                  placeholder="Usuário"
                  onChange={(e) => setUsuario(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-5">
                <Form.Control
                  id="password"
                  type="password"
                  placeholder="Senha"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" className="button-send">
                Enviar
              </Button>
            </Form>
          </div>
          <div className="form-footer"></div>
        </div>
      </div>
    </>
  )
}
export default Login
