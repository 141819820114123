import React from 'react';
import "./index.scss";

interface ReportHeaderProps {
  electionId: number;
  date: string;
  time: string;
  companyName: string;
  electionTitle: string;
  formattedStartDate: string;
  formattedEndDate: string;
  reportType: string;
}

const ReportHeader: React.FC<ReportHeaderProps> = ({ 
  electionId, 
  date, 
  time, 
  companyName, 
  electionTitle, 
  formattedStartDate, 
  formattedEndDate,
  reportType 
}) => {
  return (
    <section id="report-header">
      <div className="header-container">
        <div className="header-content">
          <div className="listing">
            <h2>Listagem</h2>
            <h2># {electionId}</h2>
          </div>
          <div className="report-type">
            <h1>{reportType}</h1>
          </div>
          <div>
            <h2>{date} {time}</h2>
            <h2 className="pageNumber"></h2>
          </div>
        </div>
        <div className="logo-container">
          <img src="/logo.png" alt="Logo" />
        </div>
      </div>
      <div className="company-info">
        <p><span className="fixed-text">Empresa - </span>{companyName}</p>
        <p><span className="fixed-text">Eleição - </span>{electionTitle.toUpperCase()}</p>
        <div className="date-info">
          <p><span className="fixed-text">Data de Início - </span>{formattedStartDate}</p>
          <p><span className="fixed-text">Data de Término - </span>{formattedEndDate}</p>
        </div>
      </div>
    </section>
  );
}

export default ReportHeader;