import React, { useEffect, useState } from "react"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import candidateService from "../../services/CandidateService"
import voteService from "../../services/VoteService"
import { useParams } from "react-router-dom"
import { Button, ProgressBar, Table } from "react-bootstrap"
import DateAndTimeDisplay from "../../components/DateAndTimeDisplay/DateAndTimeDisplay"
import { Image, ReportData } from "../../interfaces/IReportData"
import "./index.scss"

const ElectionFinalReportPage: React.FC = () => {
  const { electionId } = useParams<{ electionId: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [reportData, setReportData] = useState<ReportData>({
    votesByCandidate: [],
    images: [],
    totalElectors: 0,
    totalVoters: 0,
    totalNonVoters: 0,
    idElection: 0,
    electionTitle: "",
  })

  const {
    votesByCandidate,
    images,
    totalElectors,
    totalVoters,
    totalNonVoters,
  } = reportData
  const maxVotes =
    reportData.votesByCandidate && reportData.votesByCandidate.length > 0
      ? reportData.votesByCandidate[0].votesCount
      : 0

  useEffect(() => {
    getFinalReportData(Number(electionId))
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  async function getFinalReportData(electionId: number) {
    let images: Image[]
    let candidatesPerformance: ReportData

    await candidateService
      .getImages(electionId)
      .then((r) => {
        images = r.data
      })
      .then((r) => {
        return voteService.report(electionId)
      })
      .then((r) => {
        candidatesPerformance = r.data
        candidatesPerformance.images = images
        setReportData(candidatesPerformance)
        return reportData
      })
      .then((r) => {
        setIsLoading(false)
      })
      .catch((e) => console.error("Erro ao gerar relatório: ", e))
  }

  return (
    <div className="a4-report">
      <div className="report-header">
        <div>
          <div className="logo">
            <img src="/logo.png" alt="logo" />
          </div>
          <div className="report-title">
            Relatório final da eleição
            <span style={{ color: "#397325" }}>
              {" "}
              <b>
                <i>{reportData.electionTitle}</i>
              </b>
            </span>
          </div>
        </div>
      </div>

      <div>
        <h5 style={{ textAlign: "left", fontStyle: "italic" }}>
          <b>Votos por candidato</b>
        </h5>

        <Table hover>
          <thead>
            <tr style={{ color: "black" }}>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {votesByCandidate.map((candidate) => (
              <tr key={candidate.id}>
                <td className="column-size1">
                  {images.find((image) => image.id === candidate.id) ? (
                    <img
                      src={`data:image/jpg;base64, ${
                        images.find((image) => image.id === candidate.id)?.image
                      }`}
                      alt="Imagem do candidato"
                      width={"150"}
                      height={"150"}
                    />
                  ) : (
                    <span>Sem imagem Registrada</span>
                  )}
                </td>
                <td className="column-size2">
                  <b>{candidate.name}</b>
                </td>
                <td className="column-size3">
                  <b>{candidate.votesCount}</b>
                </td>
                <td>
                  <ProgressBar
                    variant="success"
                    now={(candidate.votesCount / maxVotes) * 100}
                    style={{ margin: "10px" }}
                  />
                </td>
              </tr>
            ))}

            <tr>
              <td className="column-size1"></td>
              <td className="column-size2">
                <div>
                  <b>Não votantes </b>
                </div>
              </td>
              <td className="column-size3">
                <div>
                  <b>{totalNonVoters}</b>
                </div>
              </td>
              <td>
                <div>
                  <ProgressBar
                    variant="danger"
                    now={(totalNonVoters / totalElectors) * 100}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>

        <div style={{ marginTop: "40px" }}>
          <p>
            <b>Total de eleitores cadastrados: {totalElectors}</b>
          </p>
          <p>
            <b>Total de eleitores que votaram: {totalVoters}</b>
          </p>
        </div>

        <div>
          <div style={{ textAlign: "right" }}>
            <b>Data e hora da emissão: </b>
            <i>
              <DateAndTimeDisplay />
            </i>
          </div>
        </div>
      </div>

      <div className="print-button-container">
        <Button
          variant="primary"
          onClick={() => window.print()}
          style={{ marginRight: "24px" }}
        >
          Imprimir
        </Button>
      </div>
    </div>
  )
}

export default ElectionFinalReportPage
