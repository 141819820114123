import axios from "axios"
import Config from "../utils/Config"
import ICompanyCreate from "../interfaces/ICompanyCreate"
import api from "./api"

class CompanyService {
  async create(data: ICompanyCreate) {
    return api({
      url: process.env.REACT_APP_API_URL + "company/",
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async listAll() {
    return api({
      url: process.env.REACT_APP_API_URL + "company/",
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async listAllByUser() {
    return api
      .get("company/all-by-user")
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async updateCompany(companyId: number, data: ICompanyCreate) {
    return api({
      url: process.env.REACT_APP_API_URL + "company/" + companyId,
      method: "PUT",
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async deleteCompany(companyId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + "company/" + companyId,
      method: "DELETE",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async listById(id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + "company/" + id,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const companyService = new CompanyService()
export default companyService
