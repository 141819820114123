import Config from "../utils/Config";
import api from "./api";

class VoteWithoutLinkService {
  async getHash(data: any): Promise<any> {
    return api({
      url: process.env.REACT_APP_API_URL + "elector/vote-without-link",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const voteWithoutLinkService = new VoteWithoutLinkService();
export default voteWithoutLinkService;
