import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import IPrivateRoute from "../../interfaces/IPrivateRoute";
import AuthContext from "../../context/auth";
import { Button } from "react-bootstrap";

const PrivateRoute = (props: IPrivateRoute) => {
    
    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setLoading] = useState<boolean>(true)
    const {getUser, signed} = useContext(AuthContext)

    useEffect(() => {
    }, [])
    
    if (!signed) return <Navigate to={"/"} />;
    if (props.role && !props.role?.includes(getUser().type)) {
    return (
      <Navigate to={"/"} />
    );
  }
  return <div>{props.element}</div>;
}

export default PrivateRoute