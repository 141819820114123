import React, { useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.scss"
import { Button, Form, Modal } from "react-bootstrap"
import voteWithoutLinkService from "../../services/VoteWithoutLinkService"
import { useLocation, useNavigate } from "react-router-dom"
import IElectionAndHash from "../../interfaces/IElectionAndHash"

const VoteWithoutLink = () => {
  const location = useLocation()
  const [documentId, setdocumentId] = useState("")
  const [companyRegistration, setcompanyRegistration] = useState("")
  const [electionNameAndHash, setElectionNameAndHash] = useState<
    IElectionAndHash[]
  >([])
  const [showElections, setShowElections] = useState<boolean>(false)
  const [path, setPath] = useState(location.state?.path)
  const navigate = useNavigate()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    let data = {
      documentId: documentId,
      companyRegistration: companyRegistration,
    }

    voteWithoutLinkService
      .getHash(data)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setElectionNameAndHash(response.data)
          setShowElections(true)
        } else if (typeof response.data === "string") {
          const hash = response.data
          if (hash === "") {
            alert("Você não tem eleições ativas")
          } else navigate(`/election/${hash}`)
        } else if (response.data === false) {
          alert("A empresa não permite votar sem link")
        }
      })
      .catch((error) => {
        console.error(error)
        alert("Usuário não encontrado")
      })
  }

  const handleCloseElections = () => {
    setShowElections(false)
  }

  return (
    <>
      <div className="container-fluid">
        <div className="logo">
          <img src="logo.png" alt="logo" />
        </div>
        <div className="form-div">
          <div className="form-header">
            <h1 className="header-login">
              Entre com seu CPF e registro da empresa para votar
            </h1>
          </div>
          <div className="form-body">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-5">
                <Form.Control
                  type="text"
                  placeholder="CPF"
                  onChange={(e) => setdocumentId(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-5">
                <Form.Control
                  type="companyRegistration"
                  placeholder="Registro da empresa"
                  onChange={(e) => setcompanyRegistration(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" className="button-send">
                Enviar
              </Button>
            </Form>
          </div>
          <div className="form-footer"></div>
        </div>
      </div>
      <Modal show={showElections} onHide={handleCloseElections}>
        <Modal.Header>
          <Modal.Title>Eleições Disponíveis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {electionNameAndHash?.length > 0 ? (
              electionNameAndHash?.map((election) => (
                <div className="flex m-3 align-items justify-between">
                  <a key={election.electionName}>{election.electionName}</a>

                  {election.voted_at ? (
                    <button
                      className="btn btn-danger h-10 w-28"
                      onClick={() =>
                        navigate(`/election/${election.electorHash}`)
                      }
                    >
                      Já Votou
                    </button>
                  ) : (
                    <button
                      className="btn btn-success h-10 w-28"
                      onClick={() =>
                        navigate(`/election/${election.electorHash}`)
                      }
                    >
                      Acessar
                    </button>
                  )}
                </div>
              ))
            ) : (
              <p>Nenhuma eleição encontrada</p>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default VoteWithoutLink
