import React, { useState, useEffect } from "react"
import companyService from "../../services/CompanyService"
import { useLocation } from "react-router-dom"
import ICompanyRead from "../../interfaces/ICompany"
import { Button, Form, Modal, Table } from "react-bootstrap"
import { PatternFormat } from "react-number-format"
import ICompany from "../../interfaces/ICompany"
import IUser from "../../interfaces/IUser"
import userCompanyService from "../../services/UserCompanyService"
import { Nav } from "../../components"

const CompanyList = () => {
  const [companies, setCompanies] = useState<ICompanyRead[]>([])
  const [allUsers, setAllUsers] = useState<IUser[]>([])
  const [usersAssociates, setUsersAssociated] = useState<IUser[]>([])
  const [name, setName] = useState("")
  const [cnpj, setCnpj] = useState("")
  const [VoteWithoutLink, setVoteWithoutLink] = useState<boolean>()
  const [show, setShow] = useState<boolean>(false)
  const [companyId, setCompanyId] = useState<number>()
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const location = useLocation()
  const [update, setUpdate] = useState<boolean>(false)
  const [showAssociate, setShowAssociate] = useState<boolean>(false)

  useEffect(() => {
    if (location.search.includes("companyAtualizada=true")) {
      listCompanies()
    }
    listCompanies()
  }, [location.search])

  const listCompanies = () => {
    companyService
      .listAll()
      .then((response) => setCompanies(response.data))
      .catch((error) => console.error("Erro ao buscar empresas:", error))
  }

  const loadAllUsersNotAssociated = () => {
    userCompanyService
      .getUsersWithoutCompany()
      .then((response) => setAllUsers(response.data))
      .catch((error) =>
        console.error("Erro ao buscar todos os usuários não associados", error)
      )
  }

  const loadUsersAssociated = (id: number) => {
    userCompanyService
      .getUsersByCompany(id)
      .then((response) => setUsersAssociated(response.data))
      .catch((error) => console.error("Erro ao buscar usuários da empresa"))
  }

  const removeAssociateUser = (userId: number) => {
    userCompanyService
      .removeAssociateUser(userId, Number(companyId))
      .then(() => {
        loadUsersAssociated(Number(companyId))
        loadAllUsersNotAssociated()
      })
  }

  const handleShowUpdate = (company: ICompany) => {
    setUpdate(true)
    setCompanyId(company.id)
    setName(company.name)
    setCnpj(company.cnpj)
    setVoteWithoutLink(company.vote_without_link)
    handleShow()
  }

  const handleShow = () => {
    setShow(true)
  }

  const handleClose = () => {
    setCompanyId(undefined)
    setSelectedUserId(null)
    setName("")
    setCnpj("")
    setUpdate(false)
    setShow(false)
    setShowAssociate(false)
    listCompanies()
  }

  const handleDelete = async (id: number) => {
    if (id !== undefined) {
      companyService.deleteCompany(id).then(() => {
        listCompanies()
      })
    }
  }

  const handleAssociate = (id: number) => {
    setCompanyId(id)
    setShowAssociate(true)
    loadAllUsersNotAssociated()
    loadUsersAssociated(id)
  }

  const handleAssociateUser = (e: React.FormEvent) => {
    if (selectedUserId && companyId) {
      userCompanyService
        .associateUserToCompany(selectedUserId, companyId)
        .then(() => {
          loadUsersAssociated(Number(companyId))
          loadAllUsersNotAssociated()
        })
        .catch((error) =>
          console.error("Erro ao associar usuário à empresa:", error)
        )
    }
  }

  const handleClick = (e: React.FormEvent) => {
    let data = {
      name: name,
      cnpj: cnpj,
      vote_without_link: VoteWithoutLink,
    }
    if (update === true && companyId) {
      companyService
        .updateCompany(companyId, data)
        .then((response) => {
          handleClose()
        })
        .catch((error) => console.error("Erro ao atualziar empresas:", error))
    } else {
      companyService
        .create(data)
        .then((response) => {
          handleClose()
        })
        .catch((error) => console.error("Erro ao cadastrar empresas:", error))
    }
  }

  return (
    <>
      <Nav />
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Empresas</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar Empresa
            </Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Código</th>
                <th style={{ width: 700 }}>Nome</th>
                <th style={{ width: 700 }}>CNPJ</th>
                <th style={{ width: 100 }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr key={company.id}>
                  <td style={{ width: 50 }}>
                    <span>{company.id}</span>
                  </td>
                  <td>
                    <span>{company.name}</span>
                  </td>
                  <td>
                    <span>
                      <PatternFormat
                        format="##.###.###/####-##"
                        value={company.cnpj}
                        displayType="text"
                        allowEmptyFormatting
                        mask="_"
                      />
                    </span>
                  </td>
                  <td style={{ width: 400 }}>
                    <button
                      className="btn btn-primary"
                      style={{ marginLeft: 10 }}
                      onClick={(e) => handleShowUpdate(company)}
                    >
                      Atualizar
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ marginLeft: 10 }}
                      onClick={(e) => handleDelete(company.id)}
                    >
                      Deletar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Empresa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCompany">Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">CNPJ</Form.Label>
              <PatternFormat
                format="##.###.###/####-##"
                allowEmptyFormatting
                mask="_"
                displayType="input"
                onValueChange={(values) => {
                  const { value } = values
                  setCnpj(value)
                }}
                step={0.1}
                className="form-control"
                name="tax"
                placeholder="000.000.000-00"
                value={cnpj}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Switch
                label="Permitir votar sem link?"
                onChange={(e) => setVoteWithoutLink(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose()
              setCompanyId(undefined)
            }}
          >
            Cancelar
          </Button>
          <Button className="btn btn-primary" onClick={(e) => handleClick(e)}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAssociate} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Associar Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <div className="flex flex-col items-center">
                <div className="mb-4">
                  <Form.Label htmlFor="inputNameCompany">
                    Adicione um usuário à empresa
                  </Form.Label>
                </div>
                <div className="mb-4">
                  <select
                    className="border rounded-md p-2"
                    value={selectedUserId || ""}
                    onChange={(e) => setSelectedUserId(Number(e.target.value))}
                  >
                    <option value="" disabled>
                      Selecione um usuário
                    </option>
                    {allUsers.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => handleAssociateUser(e)}
                  >
                    Associar à empresa
                  </Button>
                </div>
              </div>
            </Form.Group>
          </Form>
          {Array.isArray(usersAssociates) && usersAssociates.length > 0 ? (
            usersAssociates.map((user) => (
              <div className="flex m-1 align-items justify-between">
                <a key={user.id}>{user.username}</a>

                <button
                  className="btn btn-danger"
                  onClick={() => removeAssociateUser(user.id)}
                >
                  Deletar
                </button>
              </div>
            ))
          ) : (
            <p>Nenhum usuário encontrado</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose()
              setCompanyId(undefined)
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CompanyList
