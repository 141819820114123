import { useEffect, useState } from "react"
import { Nav } from "../../components"
import { Button, Form, Modal, Table } from "react-bootstrap"
import ICandidate from "../../interfaces/ICandidate"
import candidateService from "../../services/CandidateService"
import { useParams } from "react-router-dom"
import IElection from "../../interfaces/IElection"
import electionService from "../../services/ElectionService"
import ModalErrorImport from "../../components/ModalErrorImport/ModalErrorImport"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"

const CandidatesList = () => {
  const [election, setElection] = useState<IElection>()
  const [candidates, setCandidates] = useState<ICandidate[]>()
  const [candidateId, setCandidateId] = useState<number>()
  const [option, setOption] = useState<string>("")
  const [photo, setPhoto] = useState<string>()
  const [loadingImages, setLoadingImages] = useState(true)
  const [role, setRole] = useState<string>()
  const [name, setName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [document_id, setDocument_id] = useState<string>()
  const [file, setFile] = useState<File | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [images, setImages] = useState<{ id: number; image: string }[]>([])
  const [showImportModal, setShowImportModal] = useState<boolean>(false)
  const [fileSelected, setFileSelected] = useState(false)
  const [isLoadingImport, setLoadingImport] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [electionRunning, setElectionRunning] = useState(false)
  const [showErrorImportCandidates, setShowErrorImportCandidates] =
    useState<boolean>(false)
  const [fileModel, setFileModel] = useState<string>("")
  const [errorMsg, setErrorMsg] = useState<string>()
  const [imgSrc, setimgSrc] = useState<string>("")
  const { electionId } = useParams()
  const electionIdNumber = Number(electionId)

  useEffect(() => {
    getElection()
    isElectionRunning()
    listCandidates()
    getImages()
  }, [])

  const getElection = () => {
    electionService
      .getElection(electionIdNumber)
      .then((response) => {
        setElection(response.data)
        if (response.data.is_options_election === "N") {
          setFileModel("../candidates-model.xlsx")
          setimgSrc("../img-excel-candidates.png")
          setOption("Candidatos")
        } else {
          setOption("Opções")
          setFileModel("../options-model.xlsx")
          setimgSrc("../img-excel-options.png")
        }
        const today = new Date()
        const start_date_formated = new Date(response.data.start_date)
        if (today >= start_date_formated) {
          setElectionRunning(true)
        } else {
          setElectionRunning(false)
        }
      })
      .catch((error) => {
        console.error("Não foi possível obter a eleição")
      })
  }

  const isElectionRunning = () => {}

  const listCandidates = () => {
    setLoading(true)
    candidateService
      .listByElection(electionIdNumber)
      .then((response) => {
        setCandidates(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error("Não foi possível listar os candidatos")
      })
  }

  const getImages = () => {
    setLoading(true)
    setLoadingImages(true)
    candidateService
      .getImages(electionIdNumber)
      .then((response) => {
        setImages(response.data)
        setLoading(false)
        setLoadingImages(false)
      })
      .catch((error) => {
        console.error("Não foi possivel adquirir as imagens: ", error)
      })
  }

  const handleShowImport = () => {
    setShowImportModal(true)
  }

  const handleDeleteAll = () => {
    if (option === "Candidatos") {
      if (window.confirm("Deseja realmente excluir todos os candidatos?")) {
        candidateService
          .deleteAll(electionIdNumber)
          .then((response) => {
            listCandidates()
          })
          .catch((error) => {
            console.error("Não foi possível deletar os candidatos")
          })
      }
    } else {
      if (window.confirm("Deseja realmente excluir toda as opções?")) {
        candidateService
          .deleteAll(electionIdNumber)
          .then((response) => {
            listCandidates()
          })
          .catch((error) => {
            console.error("Não foi possível deletar as opções")
          })
      }
    }
  }

  const handleDeleteOne = (id: any) => {
    if (option === "Candidatos") {
      if (window.confirm("Deseja realmente excluir este candidato?")) {
        candidateService
          .deleteOne(id)
          .then((response) => {
            listCandidates()
          })
          .catch((error) => {
            console.error("Não foi possível deletar o candidato")
          })
      }
    } else {
      if (window.confirm("Deseja realmente excluir esta opção?")) {
        candidateService
          .deleteOne(id)
          .then((response) => {
            listCandidates()
          })
          .catch((error) => {
            console.error("Não foi possível deletar a opção")
          })
      }
    }
  }

  const handleShowUpdate = (candidate: ICandidate) => {
    setShowModal(true)
    setCandidateId(candidate.id)
    setPhoto(candidate.photo)
    setRole(candidate.role)
    setName(candidate.name)
    setEmail(candidate.email)
    setDocument_id(candidate.document_id)
  }

  const handleClose = () => {
    if (file) {
      listCandidates()
      getImages()
    }
    setShowModal(false)
    setFileSelected(false)
    setShowErrorImportCandidates(false)
    setShowImportModal(false)
    setCandidateId(undefined)
    setPhoto(undefined)
    setRole(undefined)
    setName(undefined)
    setEmail(undefined)
    setFile(null)
    listCandidates()
  }

  const handleUpdate = (e: React.FormEvent) => {
    if (option === "Opções") {
      if (name && candidateId) {
        let data = {
          name: name,
        }
        candidateService
          .update(data, candidateId)
          .then((response) => {
            handleClose()
          })
          .catch((error) => {
            console.error("Erro ao atualizar eleitos: ", error)
          })
      }
    }
    if (file) {
      if (name && email && role && file) {
        const body = new FormData()
        body.append("name", name)
        body.append("email", email)
        body.append("role", role)
        body.append("file", file)

        if (candidateId)
          candidateService
            .updateWithFile(body, candidateId)
            .then((response) => {
              handleClose()
            })
            .catch((error) => {
              console.error("Erro ao atualizar eleitor:", error)
            })
      }
    } else {
      let data = {
        name: name,
        email: email,
        role: role,
        document_id: document_id,
      }
      if (candidateId)
        candidateService
          .update(data, candidateId)
          .then((response) => {
            handleClose()
          })
          .catch((error) => {
            console.error("Erro ao atualizar eleitos: ", error)
          })
    }
  }

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    candidateId: number
  ) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file) {
        const reader = new FileReader()

        reader.onloadend = async () => {
          setFile(file)
          const body = new FormData()

          if (name) {
            body.append("name", name)
          }

          if (email) {
            body.append("email", email)
          }

          if (role) {
            body.append("role", role)
          }

          body.append("file", file)

          if (candidateId) {
            setLoadingImages(true)

            try {
              await candidateService.updateWithFile(body, candidateId)
              const response = await candidateService.getImages(
                electionIdNumber
              )
              setImages(response.data)
              setLoadingImages(false)
              handleClose()
            } catch (error) {
              console.error("Erro ao atualizar eleitor:", error)
            }
          }
        }

        reader.readAsDataURL(file)
      }
    }
  }

  const handleFileUpload = (event: any) => {
    setFile(event.target.files[0])
  }

  const handleExcelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileSelected(!!event.target.files?.length)
    const file = event.target.files && event.target.files[0]
    if (file) {
      setFile(file)
    }
  }

  const handleImport = (e: React.FormEvent) => {
    setLoadingImport(true)
    if (file) {
      const body = new FormData()
      body.append("file", file)
      if (election?.is_options_election === "N") {
        candidateService
          .importCandidates(body, electionIdNumber)
          .then(() => {
            setLoadingImport(false)
            handleClose()
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setErrorMsg(error.response.data.message)
            } else {
              alert("Erro desconhecido ao importar candidatos.")
            }
            setLoadingImport(false)
            setShowErrorImportCandidates(true)
          })
      } else {
        candidateService
          .importOptions(body, electionIdNumber)
          .then(() => {
            setLoadingImport(false)
            handleClose()
          })
          .catch((error) => {
            setShowErrorImportCandidates(true)
            setLoadingImport(false)
          })
      }
    }
  }

  if (candidates == null) {
    return <div>Loading...</div>
  }
  return (
    <>
      <Nav />
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>{option}</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button
              className="btn btn-secondary m-2"
              onClick={() => window.history.back()}
            >
              Voltar
            </Button>
          </div>
          <div className="d-inline-flex">
            <Button
              className="btn btn-primary m-2"
              onClick={handleShowImport}
              disabled={electionRunning}
            >
              Importar
            </Button>
          </div>
          <div className="d-inline-flex">
            <Button
              className="btn btn-danger m-2"
              onClick={handleDeleteAll}
              disabled={electionRunning}
            >
              Excluir todos
            </Button>
          </div>
          {loading ? (
            <p>Carregando...</p>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr className="tr">
                  <th>Código</th>
                  <th style={{ width: 300 }}>Foto</th>
                  <th style={{ width: 300 }}>Nome</th>
                  <th style={{ width: 300 }}>Email</th>
                  <th style={{ width: 300 }}>Documento</th>
                  <th style={{ width: 300 }}>Função</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {candidates.map((candidate) => (
                  <tr key={candidate.id}>
                    <td style={{ width: 50 }}>
                      <span>{candidate.id}</span>
                    </td>
                    <td>
                      {loadingImages && <LoadingSpinner />}
                      {images.find((image) => image.id === candidate.id) ? (
                        <img
                          src={`data:image/jpg;base64, ${
                            images.find((image) => image.id === candidate.id)
                              ?.image
                          }`}
                          alt="Imagem do candidato"
                          width={"150"}
                          height={"150"}
                        />
                      ) : (
                        !loadingImages && (
                          <>
                            <i>
                              <span>Sem imagem registrada</span>
                            </i>
                            <input
                              type="file"
                              accept="image/*"
                              id={`upload-${candidate.id}`}
                              style={{ display: "none" }}
                              onChange={(event) =>
                                handleImageUpload(event, candidate.id || 0)
                              }
                            />
                            <label
                              htmlFor={`upload-${candidate.id}`}
                              className="btn btn-success m-2"
                            >
                              Enviar foto
                            </label>
                          </>
                        )
                      )}
                    </td>
                    <td>
                      <span>{candidate.name}</span>
                    </td>
                    <td>
                      <span>{candidate.email}</span>
                    </td>
                    <td>
                      <span>{candidate.document_id}</span>
                    </td>
                    <td>
                      <span>{candidate.role}</span>
                    </td>
                    <td style={{ width: 400 }}>
                      <button
                        className="btn btn-info"
                        style={{ marginLeft: 10 }}
                        onClick={(e) => handleShowUpdate(candidate)}
                        disabled={electionRunning}
                      >
                        Alterar
                      </button>
                      <button
                        className="btn btn-danger"
                        style={{ marginLeft: 10 }}
                        onClick={(e) => handleDeleteOne(candidate.id)}
                        disabled={electionRunning}
                      >
                        Deletar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        {option === "Candidatos" && (
          <Modal.Header closeButton>
            <Modal.Title>Atualizar Candidato</Modal.Title>
          </Modal.Header>
        )}
        {option === "Opções" && (
          <Modal.Header closeButton>
            <Modal.Title>Atualizar Opção</Modal.Title>
          </Modal.Header>
        )}
        {option === "Candidatos" && (
          <Modal.Body>
            <Form>
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputBulletin">Foto</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleFileUpload}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputBulletin">Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputAgreement">Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputBulletin">Documento</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Documento"
                  value={document_id}
                  onChange={(e) => setDocument_id(e.target.value)}
                  required
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputSummary">Função</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Função"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
        )}

        {option === "Opções" && (
          <Modal.Body>
            <Form>
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputBulletin">Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose()
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleUpdate(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showImportModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Importar {option}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Control
                type="file"
                id="inputCandidates"
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleExcelChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose()
            }}
          >
            Cancelar
          </Button>
          {!isLoadingImport && (
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => handleImport(e)}
              disabled={!fileSelected}
            >
              Importar
            </Button>
          )}
          {isLoadingImport && <div>Importando...</div>}
        </Modal.Footer>
      </Modal>

      <ModalErrorImport
        show={showErrorImportCandidates}
        onHide={handleClose}
        buttonHref={fileModel}
        modalTitle={option}
        imgSrc={imgSrc}
        errorMsg={errorMsg}
      />
    </>
  )
}

export default CandidatesList
