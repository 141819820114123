import axios from "axios"
import Config from "../utils/Config"
import api from "./api"

class ElectorService {
  async importElectors(file: any, id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `importation/upload/${id}`,
      method: "POST",
      timeout: 180000,
      data: file,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
      transformRequest: (data, headers) => {
        return file
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getByHash(hash: string) {
    return api({
      url: process.env.REACT_APP_API_URL + `elector/hash/${hash}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async listAll() {
    return api({
      url: process.env.REACT_APP_API_URL + "elector/find-all",
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async listByElection(id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `elector/find/${id}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async create(data: any) {
    return api({
      url: process.env.REACT_APP_API_URL + "elector/create",
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async update(data: any, id: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `elector/${id}`,
      method: "PUT",
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async deleteOne(electorId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `elector/${electorId}`,
      method: "DELETE",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async deleteAll(electionId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `elector/delete/all/${electionId}`,
      method: "DELETE",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async sendVotingLinkToEmails(electionId: number) {
    return api({
      url:
        process.env.REACT_APP_API_URL +
        `elector/send-voting-link-to-emails/${electionId}`,
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async sendMessage(electionId: number, platform: string) {
    return api({
      url:
        process.env.REACT_APP_API_URL +
        `elector/start/${electionId}/${platform}`,
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const electorService = new ElectorService()
export default electorService
