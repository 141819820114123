import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ModalInfoProps {
  title: string;
  message: string;
  show: boolean;
  handleClose: () => void;
}

const ModalInfo: React.FC<ModalInfoProps> = ({ title, message, show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInfo;