import Config from "../utils/Config"
import api from "./api"

class LoginService {
  async login(data: any): Promise<any> {
    return api({
      url: process.env.REACT_APP_API_URL + "user/login",
      method: "POST",
      timeout: 5000,
      data: data,
      headers: { Accept: "application/json" },
    })
      .then((result) => {
        api.defaults.headers.common.Authorization = `Bearer ${result.data.access_token}`
        return Promise.resolve(result)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async logout(): Promise<any> {
    return api({
      url: process.env.REACT_APP_API_URL + "user/logout",
      method: "PUT",
      timeout: 5000,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((result) => {
        return Promise.resolve(result)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const loginService = new LoginService()
export default loginService
