import React, { useState, useEffect, useContext } from "react"
import companyService from "../../services/CompanyService"
import { Link } from "react-router-dom"
import "./index.scss"
import ICompany from "../../interfaces/ICompany"
import electionService from "../../services/ElectionService"
import { Nav } from "../../components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { Button, Modal, Form, Table } from "react-bootstrap"
import IElection from "../../interfaces/IElection"
import { ElectionType } from "../../enums/ElectionType"
import UtilDate from "../../utils/util.date"
import TinyMCEEditor from "../../components/TinyMCE/TinyMCEEditor"
import { IsOptionType } from "../../enums/IsOptionType"
import electorService from "../../services/ElectorService"
import "./index.scss"
import moment from "moment"
import candidateService from "../../services/CandidateService"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import ModalInfo from "../../components/ModalInfo/ModalInfo"
import AuthContext from "../../context/auth"
import { UserType } from "../../enums/UserType"

const ElectionList = () => {
  const [elections, setElections] = useState<IElection[] & { hasCandidates: boolean }[]>([]);
  const [election, setElection] = useState<IElection>()
  const [electionId, setElectionId] = useState<number>()
  const [companies, setCompanies] = useState<ICompany[]>()
  const [company, setCompany] = useState<string | undefined>("")
  const [title, setTitle] = useState<string | undefined>("")
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [type, setType] = useState<string | undefined>("")
  const [isOptionElection, setIsOptionElection] = useState<string | undefined>(
    ""
  )
  const [bulletin, setBulletin] = useState<string | undefined>("")
  const [auxiliarBulletin, setAuxiliarBulletin] = useState<string | undefined>()
  const [agreement, setAgreement] = useState<File | null>(null)
  const [summary, setSummary] = useState<string | undefined>("")
  const [auxiliarSummary, setAuxiliarSummary] = useState<string | undefined>("")
  const [showAdd, setShowAdd] = useState<boolean>(false)
  const [showShare, setShowShare] = useState<boolean>(false)
  const [showReportOptions, setShowReportOptions] = useState<boolean>(false)
  const [, setShowReport] = useState<boolean>(false)
  const [sendingLinkToEmails, setSendingLinkToEmails] = useState<boolean>(false)
  const [hasNoTitle, setHasNoTitle] = useState<boolean>(false)
  const [hasNoStartDate, setHasNoStartDate] = useState<boolean>(false)
  const [hasNoEndDate, setHasNoEndDate] = useState<boolean>(false)
  const [hasNoCompany, setHasNoCompany] = useState<boolean>(false)
  const [hasNoElectionType, setHasNoElectionType] = useState<boolean>(false)
  const [hasNoOptionElection, setHasNoOptionElection] = useState<boolean>(false)
  const [showModalInfo, setShowModalInfo] = useState(false)
  const { user } = useContext(AuthContext)

  const today = moment()

  useEffect(() => {
    loadElections()
    loadCompanies()
  }, [])

  const loadElections = () => {
    electionService
      .listAll()
      .then(async (response) => {
        const electionsWithElectorsCount = await Promise.all(
          response.data.map(
            async (election: IElection & { hasCandidates: boolean, electorsCount: number }) => {
              election.hasCandidates = await candidateService
                .listByElection(election.id)
                .then((response) => {
                  return response.data.length > 0
                })
                .catch((error) => {
                  console.error("Não foi possível buscar os candidatos:", error)
                  return false
                })

              const electionInfo = await electionService
                .getElectionInfo(election.id)
                .then((response) => {
                  return response.data
                })
                .catch((error) => {
                  console.error("Não foi possível buscar as informações da eleição:", error)
                  return null
                })

              if (electionInfo) {
                election.electors_count = electionInfo.electorsCount
              }

              return election
            }
          )
        )
        response.data = electionsWithElectorsCount
        setElections(response.data)
      })
      .catch((error) => console.error("Erro ao buscar Eleições:", error))
  }

  const loadCompanies = () => {
    if (user?.type === UserType.ADMIN) {
      companyService
        .listAll()
        .then((response) => {
          setCompanies(response.data)
        })
        .catch((error) => console.error("Erro ao buscar empresas:", error))
    } else {
      companyService
        .listAllByUser()
        .then((response) => {
          setCompanies(response.data)
        })
        .catch((error) => console.error("Erro ao buscar empresas:", error))
    }
  }

  if (elections == null) {
    return <div>Carregando...</div>
  }

  if (companies == null) {
    return <div>Carregando...</div>
  }

  const handleShowAddElection = () => {
    setShowAdd(true)
  }

  const handleCloseModalInfo = () => {
    setShowModalInfo(false)
  }

  const handleClose = () => {
    setShowReportOptions(false)
    setSendingLinkToEmails(false)
    setElectionId(undefined)
    setElection(undefined)
    setType(undefined)
    setIsOptionElection(undefined)
    setCompany(undefined)
    setBulletin(undefined)
    setAgreement(null)
    setSummary(undefined)
    setShowAdd(false)
    setShowShare(false)
    setShowReport(false)
    setTitle(undefined)
    setStartDate(undefined)
    setEndDate(undefined)
    loadCompanies()
    loadElections()
  }

  const handleAdd = (e: React.FormEvent) => {
    if (!title) {
      setHasNoTitle(true)
      return
    } else {
      setHasNoTitle(false)
    }

    if (!startDate) {
      setHasNoStartDate(true)
      return
    } else {
      setHasNoStartDate(false)
    }

    if (!endDate) {
      setHasNoEndDate(true)
      return
    } else {
      setHasNoEndDate(false)
    }

    if (!company) {
      setHasNoCompany(true)
      return
    } else {
      setHasNoCompany(false)
    }

    if (!isOptionElection) {
      setHasNoOptionElection(true)
      return
    } else {
      setHasNoOptionElection(false)
    }

    if (!type) {
      setHasNoElectionType(true)
      return
    } else {
      setHasNoElectionType(false)
    }

    let data = {
      title: title,

      start_date: startDate,
      end_date: endDate,
      id_company: company,
      type: type,
      bulletin: "",
      agreement: "",
      summary: "",
      is_options_election: isOptionElection,
    }
    if (type === "EXTERNAL") {
      if (
        title &&
        startDate &&
        endDate &&
        company &&
        bulletin &&
        summary &&
        agreement &&
        isOptionElection
      ) {
        const body = new FormData()
        body.append("title", title)
        body.append("start_date", startDate.toISOString())
        body.append("end_date", endDate.toISOString())
        body.append("id_company", company)
        body.append("type", type)
        body.append("bulletin", bulletin)
        body.append("summary", summary)
        body.append("is_options_election", isOptionElection)
        body.append("file", agreement)
        if (!electionId) {
          electionService
            .createWithFile(body)
            .then((response) => {
              handleClose()
            })
            .catch((error) => {})
        } else {
          electionService
            .updateWithFile(body, electionId)
            .then((response) => {
              handleClose()
            })
            .catch((error) => {
              console.error("Erro ao atualizar eleição:", error)
            })
        }
      } else {
        let data = {
          title: title,
          start_date: startDate,
          end_date: endDate,
          id_company: company,
          type: type,
          bulletin: bulletin,
          agreement: agreement,
          summary: summary,
          is_options_election: isOptionElection,
        }
        if (!electionId) {
          electionService
            .create(data)
            .then((response) => {
              handleClose()
            })
            .catch((error) => {
              console.error("Erro ao atualizar eleição:", error)
            })
        } else {
          electionService
            .update(data, electionId)
            .then((response) => {
              handleClose()
            })
            .catch((error) => {
              console.error("Erro ao cadastrar eleição:", error)
            })
        }
      }
    } else {
      if (!electionId) {
        electionService
          .create(data)
          .then((response) => {
            handleClose()
          })
          .catch((error) => {
            console.error("Erro ao atualizar eleição:", error)
          })
      } else {
        electionService
          .update(data, electionId)
          .then((response) => {
            handleClose()
          })
          .catch((error) => {
            console.error("Erro ao cadastrar eleição:", error)
          })
      }
    }
  }

  function handleOnBlur(e: any) {
    let field = e.id

    switch (field) {
      case "title":
        if (!title) {
          setHasNoTitle(true)
          return
        } else {
          setHasNoTitle(false)
        }
        break

      case startDate:
        if (!title) {
          setHasNoStartDate(true)
          return
        } else {
          setHasNoStartDate(false)
        }
        break

      case endDate:
        if (!title) {
          setHasNoEndDate(true)
          return
        } else {
          setHasNoEndDate(false)
        }
        break

      case company:
        if (!company) {
          setHasNoCompany(true)
          return
        } else {
          setHasNoCompany(false)
        }
        break

      case isOptionElection:
        if (!isOptionElection) {
          setHasNoOptionElection(true)
          return
        } else {
          setHasNoOptionElection(false)
        }
        break

      case type:
        if (!type) {
          setHasNoElectionType(true)
          return
        } else {
          setHasNoElectionType(false)
        }
        break
    }
  }

  function handleOnChangeTitle() {
    setHasNoTitle(false)
  }

  function handleOnChangeCompany() {
    setHasNoCompany(false)
  }

  function handleOnChangeOptionElection() {
    setHasNoOptionElection(false)
  }

  function handleOnChangeElectionType() {
    setHasNoElectionType(false)
  }

  const handleShowUpdate = (election: IElection) => {
    setCompany(String(election.company.id))
    setType(election.type)
    setIsOptionElection(election.is_options_election)
    setBulletin(election.bulletin)
    setSummary(election.summary)
    setElectionId(election.id)
    setTitle(election.title)
    setStartDate(new Date(election.start_date))
    setEndDate(new Date(election.end_date))
    handleShowAddElection()
  }

  const handleDelete = (id: number) => {
    if (window.confirm("Tem certeza de que deseja excluir esta eleição?")) {
      electionService
        .delete(id)
        .then(() => {
          loadElections()
        })
        .catch(() => {
          alert(
            "Não foi possível excluir a eleição porque possui dados vinculados no sistema"
          )
        })
    }
  }

  const handleBulletinChange = (content: string) => {
    setAuxiliarBulletin(content)
  }

  const handleBulletinBlur = () => {
    setBulletin(auxiliarBulletin)
  }

  const handleSummaryChange = (content: string) => {
    setAuxiliarSummary(content)
  }

  const handleSummaryBlur = () => {
    setSummary(auxiliarSummary)
  }

  const handlePdfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      setAgreement(file)
    }
  }

  const handleClickReport = (electionId: number, reportType: string) => {
    let reportUrl: string

    switch (reportType) {
      case "zerezima":
        reportUrl = `/zero-report/${electionId}`
        break
      case "voters-and-non-voters-report":
        reportUrl = `/voters-and-non-voters-report/${electionId}`
        break
      case "electors-report":
        reportUrl = `/electors-report/${electionId}`
        break
      case "final-report":
        reportUrl = `/final-report/${electionId}`
        break
      case "initial-certificate":
        reportUrl = `/initial-certificate/${electionId}`
        break
      case "final-certificate":
        reportUrl = `/final-certificate/${electionId}`
        break
      default:
        console.warn(`Unknown report type: ${reportType}`)
        return
    }

    window.open(reportUrl, "_blank")
  }

  const handleDownload = (file: any) => {
    electionService
      .getFile(file)
      .then(async (response) => {
        const fileString = response.data
        const byteCharacters = atob(fileString)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: "application/pdf" })
        const a = document.createElement("a")
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = file.display_name
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value ? new Date(e.target.value) : undefined
    selectedDate?.setHours(selectedDate.getHours() + 3)
    setStartDate(selectedDate)
    setHasNoStartDate(false)
  }

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = e.target.value ? new Date(e.target.value) : undefined
    selectedDate?.setHours(selectedDate.getHours() + 3)
    setEndDate(selectedDate)
    setHasNoEndDate(false)
  }

  const handleShowReports = (election: IElection) => {
    setElection(election)
    setElectionId(election.id)
    setShowReportOptions(true)
  }

  const checkStatus = (start_date: Date, end_date: Date) => {
    const today = moment().startOf("day")
    const start_date_formated = moment(start_date).startOf("day")
    const end_date_formated = moment(end_date).startOf("day")

    const spanStyle = {
      borderRadius: "10px", // Borda arredondada
      padding: "5px", // Espaçamento interno
      fontWeight: "bold", // Negrito
    }

    if (today.isBefore(start_date_formated)) {
      return (
        <span style={{ ...spanStyle, backgroundColor: "#fff9ae" }}>
          Não iniciada
        </span>
      )
    } else if (
      today.isBetween(start_date_formated, end_date_formated, null, "[]")
    ) {
      return (
        <span style={{ ...spanStyle, backgroundColor: "#AFE1AF" }}>
          Andamento
        </span>
      )
    } else {
      return (
        <span style={{ ...spanStyle, backgroundColor: "#ff7b7b" }}>
          Encerrada
        </span>
      )
    }
  }

  const getType = (type: string) => {
    if (type === "EXTERNAL") {
      return <span>Externa</span>
    } else if (type === "INTERNAL") {
      return <span>Interna</span>
    }
  }

  const handleShowShare = (election: IElection) => {
    setShowShare(true)
    setElectionId(election.id)
  }

  const shareLink = (platform: string, electionId?: number) => {
    if (electionId)
      if (
        window.confirm(
          `Deseja enviar o link da votação para os eleitores via ${platform}?`
        )
      ) {
        electorService
          .sendMessage(electionId, platform)
          .then((response) => {
            handleClose()
          })
          .catch((error) => {})
      }
  }

  const sendVotingLinkToEmails = (electionId?: number) => {
    if (electionId) {
      if (
        window.confirm(
          `Deseja enviar o link da votação para os eleitores via Email?`
        )
      ) {
        setShowModalInfo(true)
        setShowShare(false)
        electorService
          .sendVotingLinkToEmails(electionId)
          .then((response) => {
            handleClose()
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }

  function truncateSummary(summary: string) {
    if (summary) {
      const maxLength = 50 // Defina o comprimento máximo desejado
      if (summary.length > maxLength) {
        return summary.substring(0, maxLength) + "..." // Adiciona os três pontos (...) se o resumo for maior que o limite
      } else {
        return summary // Retorna o resumo original se estiver dentro do limite
      }
    }
    return ""
  }

  function checkIsActive(election: IElection) {
    const start_date_formated = moment(election.start_date).startOf("day")
    if (today.isAfter(start_date_formated)) {
      return true
    } else {
      return false
    }
  }

  function checkIsRunning(election: IElection) {
    const start_date_formated = moment(election.start_date).startOf("day")
    const end_date_formated = moment(election.end_date).endOf("day")
    if (today.isBetween(start_date_formated, end_date_formated)) {
      return false
    } else {
      return true
    }
  }

  function checkStarted(election?: IElection) {
    if (!election) return
    const start_date_formated = moment(election.start_date).startOf("day")
    return today.isAfter(start_date_formated)
  }

  return (
    <>
      <Nav />
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Eleições</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button
              className="btn btn-primary m-2"
              onClick={handleShowAddElection}
            >
              Cadastrar Eleição
            </Button>
          </div>
          <Table
            striped
            bordered
            hover
            style={{ width: "100%", margin: "0 auto" }}
          >
            <thead>
              <tr className="tr">
                <th style={{ width: 150 }}> Título </th>
                {/* <th style={{ width: 150 }}>Empresa</th> */}
                <th style={{ width: 100 }}>Tipo</th>
                <th style={{ width: 200 }}>Boletim</th>
                <th style={{ width: 50 }}>Acordo</th>
                <th style={{ width: 150 }}>Resumo</th>
                <th style={{ width: 100 }}>Início das votações</th>
                <th style={{ width: 100 }}>Fim das votações</th>
                <th style={{ width: 100 }}>Status</th>

                <th style={{ width: 470 }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {elections.map((el) => {
                const election = el as IElection & { hasCandidates: boolean }
                return (
                  <tr key={election.id}>
                    <td style={{ width: 50 }}>
                      <span>{election.title}</span>
                    </td>
                    {/* <td>
                    <span>{getCompanyName(election.id)}</span>
                  </td> */}
                    <td>
                      <span>{getType(election.type)}</span>
                      {/* <span>{election.type==="EXTERNAL" && "Externa")}</span> */}
                    </td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncateSummary(election.bulletin),
                        }}
                      />
                    </td>
                    {election.agreement ? (
                      <td>
                        <button
                          className="btn btn-link"
                          style={{ marginLeft: 10 }}
                          onClick={(e) => handleDownload(election.agreement)}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </button>
                      </td>
                    ) : (
                      <td></td>
                    )}

                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncateSummary(election.summary),
                        }}
                      />
                    </td>
                    <td>
                      <span>
                        {UtilDate.stringToDMY(election.start_date.toString())}
                      </span>
                    </td>
                    <td>
                      <span>
                        {UtilDate.stringToDMY(election.end_date.toString())}
                      </span>
                    </td>
                    <td>
                      <span>
                        {checkStatus(election.start_date, election.end_date)}
                      </span>
                    </td>
                    <td style={{ width: 400 }}>
                      {election.is_options_election === "N" ? (
                        <Link to={`/candidates/${election.id}`}>
                          <button
                            className="btn btn-success"
                            style={{ marginLeft: 10, width: 110 }}
                          >
                            Candidatos
                          </button>
                        </Link>
                      ) : (
                        <Link to={`/candidates/${election.id}`}>
                          <button
                            className="btn btn-success"
                            style={{
                              marginLeft: 10,
                              width: 110,
                            }}
                          >
                            Opções
                          </button>
                        </Link>
                      )}
                      <Link to={`/electors/${election.id}`}>
                        <button
                          className="btn btn-secondary"
                          style={{ marginLeft: 10 }}
                        >
                          Eleitores <span>({election.electors_count})</span>
                        </button>
                      </Link>
                      <button
                        className="btn btn-success"
                        style={{ marginLeft: 10 }}
                        onClick={(e) => handleShowShare(election)}
                        disabled={!election.hasCandidates}
                      >
                        Enviar link
                      </button>
                      <button
                        className="btn btn-primary"
                        style={{ marginLeft: 10 }}
                        onClick={(e) => handleShowUpdate(election)}
                        disabled={checkIsActive(election)}
                      >
                        Alterar
                      </button>
                      <button
                        className="btn btn-danger"
                        style={{ marginLeft: 10 }}
                        onClick={(e) => handleDelete(election.id)}
                        disabled={checkIsActive(election)}
                      >
                        Deletar
                      </button>
                      <button
                        className="btn btn-info"
                        style={{ marginLeft: 10, margin: 12 }}
                        // onClick={(e) => handleShowReport(election.id)}
                        disabled={!checkIsRunning(election)}
                        onClick={(e) => handleShowReports(election)}
                      >
                        Relatórios
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={showAdd} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Eleição</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="title">Título</Form.Label>
              <Form.Control
                className="-mt-1"
                id="title"
                name="title"
                type="text"
                placeholder="Título"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value)
                  handleOnChangeTitle()
                }}
                onBlur={(e) => handleOnBlur(e.target)}
                required
              />
              <div className="errorMessage">
                {hasNoTitle && <p>Título é obrigatório</p>}
              </div>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputStartDate">
                Início das votações
              </Form.Label>
              <Form.Control
                className="-mt-1"
                id="startDate"
                name="startDate"
                type="date"
                placeholder="Início"
                value={startDate ? startDate.toISOString().split("T")[0] : ""}
                onChange={handleStartDateChange}
                min={moment().add(1, "day").toISOString().split("T")[0]}
                onBlur={(e) => handleOnBlur(e.target)}
                onKeyDown={(event) => event.preventDefault()}
                required
              />
              <div className="errorMessage">
                {hasNoStartDate && <p>Data de início é obrigatório</p>}
              </div>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputEndDate">Fim das votações</Form.Label>
              <Form.Control
                className="-mt-1"
                type="date"
                placeholder="Fim"
                value={endDate ? endDate.toISOString().split("T")[0] : ""}
                onChange={handleEndDateChange}
                min={
                  startDate
                    ? startDate.toISOString().split("T")[0]
                    : moment().add(2, "day").toISOString().split("T")[0]
                }
                onBlur={(e) => handleOnBlur(e.target)}
                onKeyDown={(event) => event.preventDefault()}
                required
              />
              <div className="errorMessage">
                {hasNoEndDate && <p>Data de fim é obrigatório</p>}
              </div>
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Empresa</Form.Label>
              <Form.Select
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value)
                  handleOnChangeCompany()
                }}
                required
              >
                <option value=""></option>
                {companies.map((company) => (
                  <option value={company.id}>{company.name}</option>
                ))}
              </Form.Select>
              <div className="errorMessage">
                {hasNoCompany && <p>Empresa é obrigatório</p>}
              </div>
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputElectionType">
                Tipo de eleição
              </Form.Label>
              <Form.Select
                value={isOptionElection}
                onChange={(e) => {
                  setIsOptionElection(e.target.value)
                  handleOnChangeOptionElection()
                }}
                required
              >
                <option value=""></option>
                <option value={IsOptionType.N}>Candidatos</option>
                <option value={IsOptionType.Y}>Opções</option>
              </Form.Select>
              <div className="errorMessage">
                {hasNoOptionElection && (
                  <p>Eleição por candidatos ou opções é obrigatório</p>
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputType">Tipo</Form.Label>
              <Form.Select
                value={type}
                onChange={(e) => {
                  setType(e.target.value)
                  handleOnChangeElectionType()
                }}
              >
                <option value=""></option>
                <option value={ElectionType.INTERNAL}>Interna</option>
                <option value={ElectionType.EXTERNAL}>Externa</option>
              </Form.Select>
              <div className="errorMessage">
                {hasNoElectionType && <p>Tipo de eleição é obrigatório</p>}
              </div>
            </Form.Group>
            {type === "EXTERNAL" && (
              <div>
                <Form.Group className="mb-1">
                  <Form.Label htmlFor="inputBulletin">Boletim</Form.Label>
                  <TinyMCEEditor
                    value={bulletin || ""}
                    onChange={handleBulletinChange}
                    onBlur={handleBulletinBlur}
                  />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label htmlFor="inputAgreement">Acordo</Form.Label>
                  <Form.Control
                    className="-mt-1"
                    type="file"
                    id="inputAgreement"
                    accept="application/pdf"
                    onChange={handlePdfChange}
                  />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label htmlFor="inputSummary">Resumo</Form.Label>
                  <TinyMCEEditor
                    value={summary || ""}
                    onChange={handleSummaryChange}
                    onBlur={handleSummaryBlur}
                  />
                </Form.Group>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose()
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleAdd(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showShare} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-link">
            Enviar link da votação para:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ alignSelf: "center" }}>
          {/* Envie o link de votação para os eleitores. */}
          <div className="mt-2 row">
            <div className="mt-2 col">
              <button
                className="btn btn-success"
                style={{ width: 100 }}
                onClick={(e) => shareLink("WhatsApp", electionId)}
              >
                Whatsapp
              </button>
            </div>
            <div className="mt-2 col">
              <button
                className="btn btn-primary"
                style={{ width: 100 }}
                onClick={(e) => {
                  sendVotingLinkToEmails(electionId)
                }}
              >
                Email
              </button>
            </div>
            <div className="mt-2 col">
              <button
                className="btn btn-info"
                style={{ width: 100 }}
                onClick={(e) => shareLink("SMS", electionId)}
              >
                SMS
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {sendingLinkToEmails && <LoadingSpinner />}

      <ModalInfo
        title="Envio de links de votação para emails"
        message="O processo de envio de links para os emails dos eleitores foi iniciado. Quando finalizado, você receberá um email de confirmação com os detalhes"
        show={showModalInfo}
        handleClose={handleCloseModalInfo}
      />

      <Modal show={showReportOptions} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Gerar Relatórios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2">
            <div className="flex justify-between items-center">
              Zerésima
              {checkStarted(election) ? (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  disabled={true}
                >
                  Gerar
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  onClick={() => handleClickReport(electionId || 0, "zerezima")}
                >
                  Gerar
                </button>
              )}
            </div>
            <div className="flex mt-2 justify-between items-center">
              Certificado Digital Inicial
              {!checkStarted(election) ? (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  disabled={true}
                >
                  Gerar
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  onClick={(e) =>
                    handleClickReport(electionId || 0, "initial-certificate")
                  }
                >
                  Gerar
                </button>
              )}
            </div>
            <div className="flex mt-2 justify-between items-center">
              Eleitores
              {!checkStarted(election) ? (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  disabled={true}
                >
                  Gerar
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  onClick={() =>
                    handleClickReport(electionId || 0, "electors-report")
                  }
                >
                  Gerar
                </button>
              )}
            </div>
            <div className="flex mt-2 justify-between items-center">
              Votantes e não votantes
              {!checkStarted(election) ? (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  disabled={true}
                >
                  Gerar
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  onClick={() =>
                    handleClickReport(
                      electionId || 0,
                      "voters-and-non-voters-report"
                    )
                  }
                >
                  Gerar
                </button>
              )}
            </div>
            <div className="flex mt-2 justify-between items-center">
              Certificado Digital Final
              {!checkStarted(election) ? (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  disabled={true}
                >
                  Gerar
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  onClick={(e) =>
                    handleClickReport(electionId || 0, "final-certificate")
                  }
                >
                  Gerar
                </button>
              )}
            </div>
            <div className="flex mt-2 justify-between items-center">
              Relatório Final
              {!checkStarted(election) ? (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  disabled={true}
                >
                  Gerar
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ width: 100 }}
                  onClick={(e) =>
                    handleClickReport(electionId || 0, "final-report")
                  }
                >
                  Gerar
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ElectionList
