import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import electionService from "../../services/ElectionService"
import IElection from "../../interfaces/IElection"
import IElectionInfo from "../../interfaces/IElectionInfo"
import { Button, Col } from "react-bootstrap"
import ReportHeader from "../../components/ReportHeader/ReportHeader"
import voteService from "../../services/VoteService"
import IVotingReport from "../../interfaces/IVotingReport"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import { ReportType } from "../../enums/ReportType"
import ReportFinalSection from "../../components/ReportFinalSection/ReportFinalSection"
import "./index.scss"

const VotersAndNoVotersReport = () => {
  const [election, setElection] = useState<IElection>()
  const [electionInfo, setElectionInfo] = useState<IElectionInfo>()
  const [companyName, setCompanyName] = useState<string>("")
  const [electors, setElectors] = useState<IVotingReport[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { electionId } = useParams()
  const electionIdNumber = Number(electionId)

  const getReport = () => {
    return voteService
      .getVotingReport(electionIdNumber)
      .then((response) => {
        setElectors(response.data)
      })
      .catch((error) => {
        console.error("Não foi possível adquirir os votos dessa eleição")
      })
  }

  const getElection = () => {
    return electionService
      .getElection(electionIdNumber)
      .then((response) => {
        setElection(response.data)
        setCompanyName(response.data.company.name)
      })
      .catch((error) => {
        console.error("Falha ao carregar a eleição:", error)
      })
  }

  const getElectionInfo = () => {
    return electionService
      .getElectionInfo(electionIdNumber)
      .then((response) => {
        setElectionInfo(response.data)
      })
      .catch((error) => {
        console.error("Falha ao carregar informações da eleição:", error)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    Promise.all([getElection(), getElectionInfo(), getReport()])
      .then(() => setIsLoading(false))
      .catch((error) => console.error("Erro ao carregar dados:", error))
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  const now = new Date()
  const date = now.toLocaleDateString()
  const time = now.toLocaleTimeString()

  const options = {
    weekday: "long" as const,
    year: "numeric" as const,
    month: "long" as const,
    day: "numeric" as const,
  }
  let textualDate = now.toLocaleDateString("pt-BR", options)
  textualDate = textualDate.replace(/^(.)/, (match) => match.toUpperCase())

  const startDate = election ? new Date(election.start_date) : null
  const formattedStartDate = startDate
    ? `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`
    : ""

  const endDate = election ? new Date(election.end_date) : null
  const formattedEndDate = endDate
    ? `${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`
    : ""

  if (!electors) {
    return (
      <div>
        <div className="logo">
          <img src="/logo.png" alt="logo" />
        </div>
        <div className="div-already-vote">
          <h4 className="already-vote-text">
            Não foi possível gerar o relatório de votantes e não votantes.
          </h4>
          <h6 className="already-vote-description">
            Verifique se a eleição existe e ainda não foi iniciada
          </h6>
          <img src="/already-vote.png" width={300} height={287} />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="voters-non-voters-report-container">
        <ReportHeader
          electionId={election?.id ?? 0}
          date={date}
          time={time}
          companyName={companyName}
          electionTitle={election?.title ?? "-"}
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
          reportType="DE VOTANTES POR ORDEM CRONOLÓGICA"
        />

        <hr />

        <section className="report-section">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <Col>#</Col>
                </th>
                <th>
                  <Col>
                    Matrícula <br /> Data/Hora
                  </Col>
                </th>
                <th>
                  <Col>
                    Nome <br /> IP de origem
                  </Col>
                </th>
                <th>
                  <Col>Assinatura digital</Col>
                </th>
              </tr>
            </thead>

            <tbody>
              {electors.map((elector, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "white" : "lightgray",
                  }}
                >
                  <td>
                    <Col>{index + 1}</Col>
                  </td>
                  <td>
                    <Col>
                      {elector.company_registration} <br />
                      {elector.voted_at
                        ? new Date(elector.voted_at).toLocaleDateString(
                            "en-US",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          ) +
                          " - " +
                          new Date(elector.voted_at).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: false,
                            }
                          )
                        : "Não votou"}
                    </Col>
                  </td>

                  <td>
                    <Col>
                      {elector.name} <br /> {elector.ip}
                    </Col>
                  </td>
                  <td>
                    <Col></Col>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <hr />

        <section style={{ textAlign: "center" }}>
          <ReportFinalSection
            reportType={ReportType.VotersAndNonVoters}
            reportNumber={Number(electionId)} // Corrigir para número do relatório
            onPrint={() => window.print()}
          />
        </section>
      </div>
    </>
  )
}

export default VotersAndNoVotersReport
