import api from "./api"
import Config from "../utils/Config"
import IElector from "../interfaces/IElector"

class VoteService {
  async vote(candidateId: number, electorId: number) {
    return api({
      url:
        process.env.REACT_APP_API_URL +
        `vote/candidate/${candidateId}/${electorId}`,
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async report(electionId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `vote/report/${electionId}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getVotingReport(electionId: number) {
    return api({
      url:
        process.env.REACT_APP_API_URL + `election/voting-report/${electionId}`,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const voteService = new VoteService()
export default voteService
