import React, { createContext, useEffect, useState } from "react"
import { UserType } from "../enums/UserType"
interface IUserData {
  name: string
  type: UserType
}

interface AuthContextData {
  signed: boolean
  markAsSigned(): void
  markAsNotSigned(): void
  getUser(): IUserData
  selectUser(user: IUserData): void
  user?: IUserData
}

interface ProviderProps {
  children: any
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC<ProviderProps> = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [signed, setSigned] = useState(!!localStorage.getItem("hash"))
  const [user, setUser] = useState<IUserData | undefined>()

  useEffect(() => {
    const storedUser = JSON.parse(window.sessionStorage.getItem("user") || "{}");
    setUser(storedUser);
  }, []);

  function markAsSigned() {
    setSigned(true)
  }

  function markAsNotSigned() {
    setSigned(false)
  }

  function selectUser(user: IUserData) {
    window.sessionStorage.setItem("user", JSON.stringify(user))

    setUser(user)
  }

  function getUser() {
    if (user) {
      return user;
    } else {
      const storedUser = JSON.parse(window.sessionStorage.getItem("user") || "{}");
      return storedUser;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signed,
        markAsSigned,
        markAsNotSigned,
        getUser,
        selectUser,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
