import { useEffect, useState } from "react"
import { Button, Form, Modal, Table } from "react-bootstrap"
import { Nav } from "../../components"
import { useParams } from "react-router-dom"
import IElector from "../../interfaces/IElector"
import electorService from "../../services/ElectorService"
import "./index.scss"
import electionService from "../../services/ElectionService"
import IElection from "../../interfaces/IElection"
import ModalErrorImport from "../../components/ModalErrorImport/ModalErrorImport"

const ElectorsList = () => {
  const [election, setElection] = useState<IElection>()
  const [fileSelected, setFileSelected] = useState(false)
  const [electors, setElectors] = useState<IElector[]>()
  const [showUpdate, setShowUpdate] = useState<boolean>(false)
  const [showImportElectors, setShowImportElectors] = useState<boolean>(false)
  const [isLoadingImport, setLoadingImport] = useState<boolean>(false)
  const [showErrorImportElectors, setShowErrorImportElectors] =
    useState<boolean>(false)
  const [name, setName] = useState<string>()
  const [document_id, setDocument_id] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const [electorId, setElectorId] = useState<number>()
  const [electionRunning, setElectionRunning] = useState(false)
  const [file, setFile] = useState()
  const [errorMsg, setErrorMsg] = useState<string>()
  // const [electionTitle, setElectionTitle] = useState<string>();

  const { electionId } = useParams() // colocar um nome mais claro
  const electionIdNumber = Number(electionId) // colocar um nome mais claro

  const electorsFileModel = "../eleitores-modelo.xlsx"

  useEffect(() => {
    getElection()
    listElectors()
  }, [])

  const getElection = () => {
    electionService
      .getElection(electionIdNumber)
      .then((response) => {
        setElection(response.data)
        const today = new Date()
        const start_date_formated = new Date(response.data.start_date)
        if (today >= start_date_formated) {
          setElectionRunning(true)
        } else {
          setElectionRunning(false)
        }
      })
      .catch((error) => {
        console.error("Não foi possível obter a eleição")
      })
  }

  const listElectors = () => {
    electorService
      .listByElection(electionIdNumber)
      .then((response) => {
        setElectors(response.data)
      })
      .catch((error) => {
        console.error("Não foi possível listar os eleitores")
      })
  }

  const handleDeleteAll = () => {
    if (
      window.confirm(
        "Deseja realmente excluir todos os eleitores desta eleição?"
      )
    ) {
      electorService
        .deleteAll(electionIdNumber)
        .then((response) => {
          listElectors()
        })
        .catch((error) => {
          console.error("Não foi possível excluir os eleitores")
        })
    }
  }

  const handleDeleteOne = (id: any) => {
    if (window.confirm("Deseja realmente excluir este eleitor?")) {
      electorService
        .deleteOne(id)
        .then((response) => {
          listElectors()
        })
        .catch((error) => {
          console.error("Não foi possível excluir o eleitor")
        })
    }
  }

  const handleShowUpdate = (elector: IElector) => {
    setShowUpdate(true)
    setName(elector.name)
    setDocument_id(elector.document_id)
    setEmail(elector.email)
    setPhone(elector.phone)
    setElectorId(elector.id)
  }

  const handleClose = () => {
    setShowUpdate(false)
    setShowErrorImportElectors(false)
    setFileSelected(false)
    setName(undefined)
    setDocument_id(undefined)
    setEmail(undefined)
    setPhone(undefined)
    setElectorId(undefined)
    listElectors()
  }

  const handleUpdate = (e: React.FormEvent) => {
    let data = {
      name: name,
      email: email,
      phone: phone,
    }
    if (electorId)
      electorService
        .update(data, electorId)
        .then((response) => {
          handleClose()
        })
        .catch((error) => {
          console.error("Erro ao atualizar eleitor:", error)
        })
  }

  if (!electors) {
    return <div>Carregando...</div>
  }

  const handleShowImport = () => {
    setShowImportElectors(true)
  }

  const handleImportElectors = () => {
    setLoadingImport(true)
    const body = new FormData()

    if (file) {
      body.append("file", file)

      electorService
        .importElectors(body, electionIdNumber)
        .then((response) => {
          setLoadingImport(false)
          electorService
            .listByElection(electionIdNumber)
            .then((response) => {
              setElectors(response.data)
              setShowImportElectors(false)
              setFile(undefined)
            })
            .catch((error) => {
              alert("Houve um erro ao listar os eleitores")
              setShowErrorImportElectors(true)
            })
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrorMsg(error.response.data.message)
          } else {
            alert("Erro desconhecido ao importar candidatos.")
          }
          setLoadingImport(false)
          setShowErrorImportElectors(true)
        })
    }
  }

  const handleFileChoice = (e: any) => {
    setFileSelected(!!e.target.files?.length)
    const file = e.target.files[0]
    setFile(file)
  }

  return (
    <>
      <Nav />
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Eleitores</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button
              className="btn btn-secondary m-2"
              onClick={() => window.history.back()}
            >
              Voltar
            </Button>
          </div>
          <div className="d-inline-flex">
            <Button
              className="btn btn-primary m-2"
              onClick={handleShowImport}
              disabled={electionRunning}
            >
              Importar
            </Button>
          </div>
          <div className="d-inline-flex">
            <Button
              className="btn btn-danger m-2"
              onClick={handleDeleteAll}
              disabled={electionRunning}
            >
              Excluir todos
            </Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Código</th>
                <th style={{ width: 300 }}>Nome</th>
                <th style={{ width: 300 }}>Documento</th>
                <th style={{ width: 300 }}>Email</th>
                <th style={{ width: 300 }}>Telefone</th>
                <th style={{ width: 300 }}>Registro da Empresa</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {electors.map((elector) => (
                <tr key={elector.id}>
                  <td style={{ width: 50 }}>
                    <span>{elector.id}</span>
                  </td>
                  <td>
                    <span>{elector.name}</span>
                  </td>
                  <td>
                    <span>{elector.document_id}</span>
                  </td>
                  <td>
                    <span>{elector.email}</span>
                  </td>
                  <td>
                    <span>{elector.phone}</span>
                  </td>
                  <td>
                    <span>{elector.company_registration}</span>
                  </td>
                  <td style={{ width: 400 }}>
                    <button
                      className="btn btn-info"
                      style={{ marginLeft: 10 }}
                      onClick={(e) => handleShowUpdate(elector)}
                      disabled={electionRunning}
                    >
                      Alterar
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ marginLeft: 10 }}
                      onClick={(e) => handleDeleteOne(elector.id)}
                      disabled={electionRunning}
                    >
                      Deletar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal show={showImportElectors} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Importar Eleitores</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Control
                type="file"
                id="inputElectors"
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileChoice}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowImportElectors(false)
            }}
          >
            Cancelar
          </Button>

          {!isLoadingImport && (
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => handleImportElectors()}
              disabled={!fileSelected}
            >
              Importar
            </Button>
          )}
          {isLoadingImport && <div>Importando...</div>}
        </Modal.Footer>
      </Modal>

      <ModalErrorImport
        show={showErrorImportElectors}
        onHide={handleClose}
        modalTitle="Eleitores"
        buttonHref={electorsFileModel}
        imgSrc="../img-excel-electors.png"
        errorMsg={errorMsg}
      />

      <Modal show={showUpdate} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Atualizar Eleitor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputBulletin">Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputBulletin">Documento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Documento"
                value={document_id}
                onChange={(e) => setDocument_id(e.target.value)}
                required
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputAgreement">Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputSummary">Telefone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Telefone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose()
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleUpdate(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ElectorsList
