import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import IElector from "../../interfaces/IElector"
import electorService from "../../services/ElectorService"
import ICandidate from "../../interfaces/ICandidate"
import candidateService from "../../services/CandidateService"
import "./index.scss"
import { Modal } from "react-bootstrap"
import electionService from "../../services/ElectionService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faFilePdf } from "@fortawesome/free-solid-svg-icons"
import voteService from "../../services/VoteService"
import moment from "moment"

const Vote = () => {
  const [elector, setElector] = useState<IElector>()
  const [candidates, setCandidates] = useState<ICandidate[]>()
  const [voteCandidate, setVoteCandidate] = useState<ICandidate>()
  const [images, setImages] = useState<{ id: number; image: string }[]>([])
  const [showBulletin, setShowBulletin] = useState<boolean>(false)
  const [activeElection, setActiveElection] = useState<boolean>(false)
  const [messageElection, setMessageElection] = useState<string>()
  const [selectCandidate, setSelectCandidate] = useState<number>()
  const [showSuccessVote, setShowSuccessVote] = useState<boolean>(false)
  const [showTOSModal, setShowTOSModal] = useState<boolean>(true)
  const { hash } = useParams()
  const somConfirmacao = new Audio("/confirma-urna.mp3")
  somConfirmacao.volume = 0.1

  useEffect(() => {
    checkHash()
  }, [])

  const checkHash = () => {
    if (hash)
      electorService
        .getByHash(hash)
        .then((response) => {
          setElector(response.data)
          candidateService
            .listByElection(response.data.election.id)
            .then((response) => {
              response.data.sort((a: any, b: any) => {
                // Converte os nomes para letras minúsculas para garantir uma ordenação sem distinção entre maiúsculas e minúsculas
                const nomeA = a.name.toLowerCase()
                const nomeB = b.name.toLowerCase()

                if (nomeA < nomeB) {
                  return -1 // Retorna um valor negativo se o nomeA deve vir antes do nomeB
                }
                if (nomeA > nomeB) {
                  return 1 // Retorna um valor positivo se o nomeA deve vir depois do nomeB
                }
                return 0 // Retorna 0 se os nomes forem iguais
              })
              setCandidates(response.data)
            })
            .catch((error) => {
              console.error("Não foi possível listar os candidatos")
            })
          candidateService
            .getImages(response.data.election.id)
            .then((response) => {
              setImages(response.data)
            })
            .catch((error) => {
              console.error("Não foi possivel adquirir as imagens: ", error)
            })
          const today = moment()
          const start_date_formated = moment(
            response.data.election.start_date
          ).startOf("day")
          const end_date_formated = moment(
            response.data.election.end_date
          ).endOf("day")
          if (today.isBefore(start_date_formated)) {
            setMessageElection("A votação ainda não iniciou")
          } else if (today.isAfter(end_date_formated)) {
            setMessageElection("A votação já expirou")
          } else if (today.isBetween(start_date_formated, end_date_formated)) {
            setActiveElection(true)
          }
        })
        .catch((error) => console.error("Erro ao buscar eleitor:", error))
  }

  const handleDownload = (file: any) => {
    electionService
      .getFile(file)
      .then(async (response) => {
        const fileString = response.data
        const byteCharacters = atob(fileString)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: "application/pdf" })
        const a = document.createElement("a")
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = file.display_name
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleClose = () => {
    setShowBulletin(false)
  }

  const handleSelect = (index: number, candidate: ICandidate) => {
    if (index === selectCandidate) {
      setSelectCandidate(undefined)
      setVoteCandidate(undefined)
    } else {
      setSelectCandidate(index)
      setVoteCandidate(candidate)
    }
  }

  const handleVote = () => {
    if (voteCandidate?.id && elector?.id) {
      voteService
        .vote(voteCandidate.id, elector.id)
        .then((response) => {
          somConfirmacao.play()
          setShowSuccessVote(true)
        })
        .catch((error) => {
          console.error("Erro ao votar: ", error)
        })
    }
  }

  if (elector?.voted_at != null) {
    return (
      <div>
        <div className="logo">
          <img src="/logo.png" alt="logo" />
        </div>
        <div className="div-already-vote">
          <h4 className="already-vote-text">Você já votou</h4>
          <h6 className="already-vote-description">
            É permitido apenas 1 voto por pessoa
          </h6>
          <img src="/already-vote.png" width={300} height={287} />
          <Link to="/">
            <button className="exit-button">
              <img src="/exit-button.png" />
              Sair
            </button>
          </Link>
        </div>
      </div>
    )
  }

  if (!candidates || !elector) {
    return <p>Loading...</p>
  }

  if (!activeElection) {
    return (
      <div>
        <div className="logo">
          <img src="/logo.png" alt="logo" />
        </div>
        <div className="div-already-vote">
          <h4 className="already-vote-text">Votação Inativa</h4>
          <h6 className="already-vote-description">{messageElection}</h6>
          <img src="/already-vote.png" width={300} height={287} />
          <Link to="/">
            <button className="exit-button">
              <img src="/exit-button.png" />
              Sair
            </button>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="logo">
        <img src="/logo.png" alt="logo" />
      </div>
      <div className="content">
        <div className="text-div">
          {elector?.election.type === "EXTERNAL" ? (
            <div className="">
              <h4 className="vote-text">Resumo</h4>
              <br />
              <div
                className="vote-summary"
                dangerouslySetInnerHTML={{ __html: elector.election.summary }}
              />

              <button
                className="bulletinButton"
                onClick={(e) => setShowBulletin(true)}
              >
                LEIA O BOLETIM
              </button>
            </div>
          ) : (
            <p></p>
          )}
          <h4 className="vote-text">Vote em seu candidato</h4>
        </div>
        <div className="candidates-container">
          {candidates.map((candidate, index: number) => (
            <button
              key={index}
              className={`${
                index === selectCandidate ? "selectedCandidate" : "candidate"
              }`}
              onClick={() => handleSelect(index, candidate)}
            >
              <div className="info">
                {elector.election.is_options_election === "N" && (
                  <div>
                    {images.find((image) => image.id === candidate.id) ? (
                      <img
                        src={`data:image/jpg;base64, ${
                          images.find((image) => image.id === candidate.id)
                            ?.image
                        }`}
                        alt="Imagem do candidato"
                        width={"60"}
                        height={"60"}
                      />
                    ) : (
                      <img
                        src="/user.jpg"
                        alt="Imagem do candidato"
                        width={"60"}
                        height={"60"}
                      />
                    )}
                  </div>
                )}
                {index === selectCandidate ? (
                  <h4 className="select-name-text">{candidate.name}</h4>
                ) : (
                  <h4 className="name-text">{candidate.name}</h4>
                )}
              </div>
              {index === selectCandidate && (
                <img
                  className="check-icon"
                  src="/check-circle.png"
                  alt="Check Verde"
                />
              )}
            </button>
          ))}
        </div>
      </div>
      {voteCandidate !== undefined && (
        <div className="confirm-vote-container">
          <button className="confirm-vote-button" onClick={(e) => handleVote()}>
            <img
              className="check-vote-icon"
              src="/confirm-vote.png"
              alt="Check Verde"
            />
            CONFIRMAR VOTO
          </button>
        </div>
      )}

      {showSuccessVote && (
        <div className="overlay">
          <div className="alert-modal">
            <img src="/success-vote.png" />
            <p className="alert-text">Seu voto foi computado!</p>
          </div>
          <div className="alert-button-modal">
            <Link to="/vote-without-link">
              <button className="alert-button">
                <img src="/exit-button.png" />
                <h4 className="alert-button-text">Sair da Votação</h4>
              </button>
            </Link>
          </div>
        </div>
      )}
      <Modal show={showBulletin} onHide={handleClose} dialogClassName="modal">
        <Modal.Body>
          <button className="close-button" onClick={(e) => handleClose()}>
            <img
              src="/close-modal.png"
              alt="Close Modal"
              width={24}
              height={24}
            />
          </button>
          <h4 className="vote-text">Boletim</h4>
          <br />
          <div
            className="vote-summary"
            dangerouslySetInnerHTML={{ __html: elector.election.bulletin }}
          />
          <button
            type="submit"
            className="button-modal"
            onClick={(e) => handleDownload(elector.election.agreement)}
          >
            <FontAwesomeIcon icon={faFilePdf} />
            <a style={{ marginLeft: "10px" }}>VEJA O ACORDO</a>
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={showTOSModal} dialogClassName="modal">
        <Modal.Body>
          <h4 className="vote-text">Termos de Uso e Política de Privacidade</h4>
          <div className="vote-summary">
            Ao continuar, você concorda com os{" "}
            <a
              href="https://sindmetallorena.com.br/termos-de-uso"
              target="_blank"
              rel="noreferrer"
            >
              Termos de Uso
            </a>{" "}
            e a{" "}
            <a
              href="https://sindmetallorena.com.br/politica-de-privacidade"
              target="_blank"
              rel="noreferrer"
            >
              Política de Privacidade
            </a>
            {"."}
          </div>
          <button
            type="submit"
            className="button-modal"
            onClick={() => setShowTOSModal(false)}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
            <a style={{ marginLeft: "10px" }}>Continuar</a>
          </button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Vote
