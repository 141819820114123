import axios from "axios"
import Config from "../utils/Config"
import api from "./api"

class UserCompanyService {
  async list() {
    return api({
      url: process.env.REACT_APP_API_URL + "user-company/associations",
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getUsersByCompany(id: number) {
    return api({
      url:
        process.env.REACT_APP_API_URL + "user-company/company/" + id + "/users",
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getAllUsersNotAssociated(id: number) {
    return api({
      url:
        process.env.REACT_APP_API_URL +
        "user-company/users/not-associated/" +
        id,
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getUsersWithoutCompany() {
    return api({
      url: process.env.REACT_APP_API_URL + "user-company/users/not-associated/",
      method: "GET",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async associateUserToCompany(userId: number, companyId: number) {
    return api({
      url:
        process.env.REACT_APP_API_URL +
        "user-company/associate/" +
        userId +
        "/" +
        companyId,
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async removeAssociateUser(userId: number, companyId: number) {
    return api({
      url:
        process.env.REACT_APP_API_URL +
        "user-company/remove/" +
        userId +
        "/" +
        companyId,
      method: "DELETE",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async changeCompanyAssociated(userId: number, newCompanyId: number) {
    return api({
      url:
        process.env.REACT_APP_API_URL +
        `user-company/update/${userId}/${newCompanyId}`,
      method: "POST",
      timeout: Config.TIMEOUT_REQUEST,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const userCompanyService = new UserCompanyService()
export default userCompanyService
