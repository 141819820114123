import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import electionService from "../../services/ElectionService"
import IElection from "../../interfaces/IElection"
import IElectionInfo from "../../interfaces/IElectionInfo"
import ReportHeader from "../../components/ReportHeader/ReportHeader"
import { Button } from "react-bootstrap"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import ReportFinalSection from "../../components/ReportFinalSection/ReportFinalSection"
import { ReportType } from "../../enums/ReportType"
import "./index.scss"

const FinalCertificate = () => {
  const [election, setElection] = useState<IElection>()
  const [electionInfo, setElectionInfo] = useState<IElectionInfo>()
  const [companyName, setCompanyName] = useState<string>("")
  const [finalCertificate, setFinalCertificate] = useState<string>('');
  const [initialCertificate, setinItialCertificate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { electionId } = useParams()
  const electionIdNumber = Number(electionId)

  const getElection = () => {
    return electionService
      .getElection(electionIdNumber)
      .then((response) => {
        setElection(response.data)
        setCompanyName(response.data.company.name)
        setinItialCertificate(response.data.initial_certificate)
        setFinalCertificate(response.data.final_certificate)
      })
      .catch((error) => {
        console.error("Failed to fetch election:", error)
      })
  }

  const getElectionInfo = () => {
    return electionService
      .getElectionInfo(electionIdNumber)
      .then((response) => {
        setElectionInfo(response.data)
      })
      .catch((error) => {
        console.error("Failed to fetch election info:", error)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    Promise.all([getElection(), getElectionInfo()])
      .then(() => setIsLoading(false))
      .catch((error) => console.error("Erro ao carregar dados:", error))
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  const now = new Date()
  const date = now.toLocaleDateString()
  const time = now.toLocaleTimeString()

  const options = {
    weekday: "long" as const,
    year: "numeric" as const,
    month: "long" as const,
    day: "numeric" as const,
  }
  let textualDate = now.toLocaleDateString("pt-BR", options)
  textualDate = textualDate.replace(/^(.)/, (match) => match.toUpperCase())

  const startDate = election ? new Date(election.start_date) : null
  const formattedStartDate = startDate
    ? `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`
    : ""

  const endDate = election ? new Date(election.end_date) : null
  const formattedEndDate = endDate
    ? `${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`
    : ""

  if (!election || !electionInfo) {
    return (
      <div>
        <div className="logo">
          <img src="/logo.png" alt="logo" />
        </div>
        <div className="div-already-vote">
          <h4 className="already-vote-text">
            Não foi possível gerar o certificado.
          </h4>
          <h6 className="already-vote-description">
            Verifique se a eleição existe
          </h6>
          <img src="/already-vote.png" width={300} height={287} />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="report-container">
        <ReportHeader
          electionId={election.id}
          date={date}
          time={time}
          companyName={companyName}
          electionTitle={election.title}
          formattedStartDate={formattedStartDate}
          formattedEndDate={formattedEndDate}
          reportType="Certificado Digital Final"
        />

        <hr />

        <section className="report-section">
          <h1 className="header">Declaração</h1>
          <div>
            <p className="bold-text">
              O presente certificado demonstra que a entidade acima qualificada
              seguiu corretamente todos os passos para realizar uma eleição
              eletrônica na plataforma de eleiçoes pela internet do fornecedor
              abaixo qualificado e que os dados cadastrados no bancos de dados
              foram assinados eletrônicamente e não foram alterados.
            </p>
          </div>
        </section>
        <section>
          <div className="total-container">
            <h1 className="header">Total</h1>
            <div className="flex-around">
              <h4>Eleitores({electionInfo.electorsCount})</h4>
              <h4>Candidatos({electionInfo.candidatesCount})</h4>
              <h4>Votos({electionInfo.votesCount})</h4>
            </div>
          </div>
        </section>
        <br></br>
        <section>
          <div>
            <div className="flex-between">
              <div className="flex-container">
                <h4>Certificado inicial:</h4>
                <h4>{initialCertificate}</h4>
               </div>
              <div className="flex-container">
                  <h4>Certificado final:</h4>
                  <h4>{finalCertificate}</h4>
              </div>
            </div>
          </div>
        </section>

        <section style={{ textAlign: "center" }}>
          <ReportFinalSection
            reportType={ReportType.FinalCertificate}
            reportNumber={Number(electionId)} // Corrigir para número do relatório
            onPrint={() => window.print()}
          />
        </section>
      </div>
    </>
  )
}

export default FinalCertificate
