import { useEffect, useState } from "react"
import { Button, Modal, Form, Table, Alert } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.scss"
import "../../shared/global.scss"
import UtilDate from "../../utils/util.date"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker"
import ptBR from "date-fns/locale/pt-BR"
import { PatternFormat } from "react-number-format"
import userService from "../../services/UserService"
import IUser from "../../interfaces/IUser"
import IUserCompany from "../../interfaces/IUserCompany"
import { UserType } from "../../enums/UserType"
import { ActiveType } from "../../enums/ActiveType"
import userCompanyService from "../../services/UserCompanyService"
import ICompany from "../../interfaces/ICompany"
import companyService from "../../services/CompanyService"
import { Nav } from "../../components"

registerLocale("pt-BR", ptBR)

const UserList = () => {
  const [items, setItems] = useState<IUser[]>([])
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [companyId, setCompanyId] = useState<number>()
  const [currentUserCompany, setCurrentUserCompany] = useState<ICompany>()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [userId, setUserId] = useState<number>()
  const [document, setDocument] = useState<string>()
  const [name, setName] = useState<string>()
  const [email, setEmail] = useState<string>("")
  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [passwordCompare, setPasswordCompare] = useState<string>()
  const [type, setType] = useState<string | undefined>("ADMIN")
  const [phone, setPhone] = useState<string>()
  const [activeStatus, setActiveStatus] = useState<string | undefined>("YES")
  const [show, setShow] = useState<boolean>(false)
  const [showCompanies, setShowCompanies] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [passError, setPassError] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)
  const [userCompany, setUserCompany] = useState<IUserCompany[]>([])
  const [editing, setEditing] = useState<boolean>(false)

  const loadList = () => {
    setLoading(true)

    userService
      .getAll()
      .then((response) => {
        let users: IUser[] = response.data
        setItems(users)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadList()
    loadUserCompany()
    loadCompanies()
  }, [])

  const loadUserCompany = () => {
    setLoading(true)
    userCompanyService
      .list()
      .then((response) => {
        let userCompany: IUserCompany[] = response.data
        setUserCompany(userCompany)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const loadCompanies = () => {
    setLoading(true)
    companyService
      .listAll()
      .then((response) => {
        setCompanies(response.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  useEffect(() => {}, [type, activeStatus])

  const handleShowUpdate = (user: IUser) => {
    handleShowAssociate(user.id)
    setDocument(user.document)
    setName(user.name)
    setEmail(user.email)
    setUsername(user.username)
    setType(user.type)
    setActiveStatus(user.active)
    setPhone(user.phone)
    setPassword("")
    setPasswordCompare("")
    setEditing(true)
    handleShow()
  }

  const handleClick = (e: React.FormEvent) => {
    e.preventDefault()
    var emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    if (email && emailRegex.test(email)) {
      let data = {
        name: name,
        email: email,
        username: username,
        type: type,
        active: activeStatus,
        phone: phone,
        document: document,
        password,
      }
      if (password === passwordCompare) {
        if (userId) {
          userService
            .update(data, userId)
            .then((response) => {
              handleAssociate()
              loadList()
              handleClose()
              setUserId(undefined)
            })
            .catch((error) => {
              setError(true)
            })
        } else {
          data.password = password
          userService
            .create(data)
            .then((response) => {
              setUserId(response.data)
              handleAssociate(response.data)
              loadList()
              handleClose()
            })
            .catch(() => {
              setError(true)
            })
        }
      } else {
        setPassError(true)
      }
    } else {
      setEmailError(true)
    }
  }

  const handleDelete = async (id: number) => {
    if (window.confirm("Tem certeza de que deseja excluir este usuário?")) {
      userService
        .delete(id)
        .then((response) => {
          loadList()
        })
        .catch((error) => {
          alert(
            "Não foi possível excluir o usuário porque possui dados vinculados no sistema"
          )
        })
    }
  }

  const handleClose = () => {
    setDocument(undefined)
    setName(undefined)
    setEmail("")
    setUsername(undefined)
    setUserId(undefined)
    setPassword(undefined)
    setPasswordCompare(undefined)
    setType(undefined)
    setPhone(undefined)
    setEditing(false)
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
    setError(false)
    setPassError(false)
  }

  const handleCloseCompanies = () => {
    setUserId(undefined)
    setCompanyId(undefined)
    setCurrentUserCompany(undefined)
    loadCompanies()
    loadUserCompany()
    setShowCompanies(false)
  }

  const getUserType = (type: string) => {
    const types: { [key: string]: string } = {
      ADMIN: "Administrador",
      MANAGER: "Gerente",
    }
    return types[type]
  }

  const getActiveType = (activeStatus: string) => {
    const activeStatuses: { [key: string]: string } = {
      Y: "Sim",
      N: "Não",
    }
    return activeStatuses[activeStatus]
  }

  const getCompanyName = (userId: number) => {
    const company = userCompany.find((uc) => {
      return uc.user?.id === userId
    })
    return company ? company.company.name : "Não vinculado"
  }

  const handleCompanySelection = (companyId: any) => {
    setCompanyId(companyId)
  }

  const handleAssociate = (newUserId?: number) => {
    if (currentUserCompany && companyId) {
      onUpdateAssociation(companyId)
    } else if (newUserId) {
      onCreateAssociation(newUserId, companyId)
    }
  }

  const onCreateAssociation = (newUserId: number, companyId: any) => {
    if (newUserId && companyId) {
      userCompanyService
        .associateUserToCompany(newUserId, companyId)
        .then(() => {
          handleCloseCompanies()
        })
        .catch((error) =>
          console.error("Erro ao associar usuário à empresa:", error)
        )
    }
  }

  const onUpdateAssociation = (companyId: number) => {
    if (userId) {
      userCompanyService
        .changeCompanyAssociated(userId, companyId)
        .then((response) => {
          handleCloseCompanies()
        })
    }
  }

  const handleShowAssociate = (userId: number) => {
    setUserId(userId)

    const currentUserCompany = userCompany.find((uc) => uc.user.id === userId)
    if (currentUserCompany) {
      setCurrentUserCompany(currentUserCompany.company)
      setCompanyId(currentUserCompany.company.id)
    } else {
      setCurrentUserCompany(undefined)
      setCompanyId(undefined)
    }
  }

  return (
    <>
      <Nav />
      <div>
        <div className="container-fluid main-container">
          <div className="top-bar">
            <div className="title">
              <h3>Usuários</h3>
            </div>

            <div id="container-filters">
              <div className="top-bar-collor"></div>
            </div>
          </div>
          <div className="d-inline-flex">
            <Button className="btn btn-primary m-2" onClick={handleShow}>
              Cadastrar usuário
            </Button>
          </div>

          <br />
          <Table striped bordered hover>
            <thead>
              <tr className="tr">
                <th>Código</th>
                <th style={{ width: 300 }}>Nome</th>
                <th style={{ width: 200 }}>Usuário</th>
                <th style={{ width: 300 }}>Empresa</th>
                <th style={{ width: 150 }}>Tipo</th>
                <th style={{ width: 150 }}>Documento</th>
                <th style={{ width: 100 }}>Ativo?</th>
                <th style={{ width: 200 }}>Criado em</th>
                <th style={{ width: 100 }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Carregando...</p>
                  </td>
                </tr>
              )}
              {items.map((user: IUser) => (
                <tr key={user.id}>
                  <td style={{ width: 50 }}>
                    <span>{user.id}</span>
                  </td>
                  <td>
                    <span>{user.name}</span>
                  </td>
                  <td>
                    <span>{user.username}</span>
                  </td>
                  <td>{getCompanyName(user.id)}</td>
                  <td style={{ width: 120 }}>
                    <span>{getUserType(user.type)}</span>
                  </td>
                  <td>
                    <span>
                      <PatternFormat
                        format="###.###.###-##"
                        displayType="text"
                        value={user.document}
                        allowEmptyFormatting
                        mask="_"
                      />
                    </span>
                  </td>
                  <td style={{ width: 120 }}>
                    <span>{getActiveType(user.active)}</span>
                  </td>
                  <td style={{ width: 120 }}>
                    <span>
                      {UtilDate.stringToDMYHM(user.created_at.toString())}
                    </span>
                  </td>
                  {/* <td style={{minWidth: 250}}>
                      {user.firms?.length > 0 &&
                        <span>{user.firms?.map((firm) => firm.name).join(",")}</span>
                      }
                      {user.firms?.length == 0 &&
                        <span>Nenhuma empresa vinculada</span>
                      }
                    </td> */}
                  <td style={{ width: 400 }}>
                    <button
                      className="btn btn-primary"
                      style={{ marginLeft: 10 }}
                      onClick={(e) => handleShowUpdate(user)}
                    >
                      Atualizar
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{ marginLeft: 10 }}
                      onClick={(e) => handleDelete(user.id)}
                    >
                      Deletar
                    </button>
                  </td>
                </tr>
              ))}
              {items.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={6}>
                    <p>Nenhum usuário encontrado.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editing ? "Atualizar" : "Cadastrar"} Usuário
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Nome</Form.Label>
              <Form.Control
                className="-mt-1"
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Usuário</Form.Label>
              <Form.Control
                className="-mt-1"
                type="text"
                placeholder="Nome de usuário"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Email</Form.Label>
              <Form.Control
                className="-mt-1"
                type="email"
                autoComplete={editing ? "off" : "email"}
                placeholder="Email"
                value={email.toLowerCase()}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Senha</Form.Label>
              {editing && (
                <p className="passwordInfo">
                  Se não preencher a senha, não será alterada
                </p>
              )}
              <Form.Control
                className="-mt-1"
                type="password"
                autoComplete={editing ? "off" : "new-password"}
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">
                Confirme a senha
              </Form.Label>
              <Form.Control
                className="-mt-1"
                type="password"
                placeholder="Senha"
                value={passwordCompare}
                onChange={(e) => setPasswordCompare(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">CPF</Form.Label>
              <PatternFormat
                format="###.###.###-##"
                allowEmptyFormatting
                mask="_"
                displayType="input"
                onValueChange={(values) => {
                  const { value } = values
                  setDocument(value)
                }}
                step={0.1}
                className="form-control -mt-1"
                name="tax"
                placeholder="000.000.000-00"
                value={document}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Telefone</Form.Label>
              <PatternFormat
                format="(##)#####-####"
                allowEmptyFormatting
                mask="_"
                displayType="input"
                onValueChange={(values) => {
                  const { value } = values
                  setPhone(value)
                }}
                step={0.1}
                className="form-control -mt-1"
                name="tax"
                placeholder="(00)00000-0000"
                value={phone}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Tipo</Form.Label>
              <Form.Select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value={UserType.ADMIN}>Administrador</option>
                <option value={UserType.MANAGER}>Gerente</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label htmlFor="inputNameCustomer">Ativo?</Form.Label>
              <Form.Select
                value={activeStatus}
                onChange={(e) => setActiveStatus(e.target.value)}
              >
                <option value={ActiveType.YES}>Sim</option>
                <option value={ActiveType.NO}>Não</option>
              </Form.Select>
            </Form.Group>

            {type === "MANAGER" && (
              <Form.Group className="mb-1">
                <Form.Label htmlFor="inputNameCustomer">
                  Selecione uma empresa:
                </Form.Label>
                <Form.Select
                  value={companyId}
                  onChange={(e) => handleCompanySelection(e.target.value)}
                >
                  <option value="">Selecione...</option>
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          </Form>
          {passError && (
            <Alert
              variant="danger"
              onClose={() => setPassError(false)}
              dismissible
            >
              As senhas não coincidem
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Usuário já cadastrado
            </Alert>
          )}
          {emailError && (
            <Alert
              variant="danger"
              onClose={() => setEmailError(false)}
              dismissible
            >
              E-mail inválido
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose()
              setUserId(undefined)
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleClick(e)}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCompanies} onHide={handleCloseCompanies}>
        <Modal.Header closeButton>
          <Modal.Title>Vincular Empresa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {companies.map((company) => (
              <div key={company.id}>
                <input
                  type="radio"
                  id={`company-${company.id}`}
                  name="company"
                  value={company.id}
                  checked={companyId === company.id}
                  onChange={() => handleCompanySelection(company.id)}
                />
                <label
                  htmlFor={`company-${company.id}`}
                  style={{ marginLeft: 10 }}
                >
                  {company.name}
                </label>
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCompanies}>
            Cancelar
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => handleAssociate()}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UserList
