import React from 'react';
import { Button } from 'react-bootstrap';

interface FinalSectionProps {
  reportType: string;
  reportNumber: number;
  onPrint: () => void;
}

const ReportFinalSection: React.FC<FinalSectionProps> = ({ reportType, reportNumber, onPrint }) => (
  <section >
    <div className="final-section">
      <h1>Administradores</h1>
      <h4>Relatório</h4>
      <p>{reportType}, <b>{reportNumber}</b> - {new Date().toLocaleString()}</p>
    </div>
    <div className="button-section">
      <Button variant="primary" onClick={onPrint} className="no-print">Imprimir</Button>
    </div>
  </section>
);

export default ReportFinalSection;