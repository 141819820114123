import { useState, useEffect } from 'react';

const DateAndTimeDisplay = () => {

  const [currentDateTime, setCurrentDateTime] = useState('');

  const getCurrentDateTime = () => {
    const now = new Date();
    const date = now.toLocaleDateString();
    const time = now.toLocaleTimeString();
    return `${date} ${time}`; 
  };

  useEffect(() => {
      setCurrentDateTime(getCurrentDateTime());
  }, []);

  return (
    <p>{currentDateTime}</p>
  );
};

export default DateAndTimeDisplay;